import { Divider, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const CourseCard = ({ course, textStyle }) => {
  return (
    <>
        <Link to={`/course/${course._id}`}>
      <Flex align={"center"} justify={"space-between"} width={"100%"} _hover={{ bg: "gray.200" }} p={2}>
          <Flex flex={1} align={"center"} gap={4}>
            <Image src={course.banner.url} alt={course.title} height={"50px"} w={"100px"} />
            <Flex flexDir={"column"}>
              <Text fontWeight={600} style={textStyle}>{course.title}</Text>
              <Text style={textStyle}>
                {course.creator.firstName} {course.creator.lastName}
              </Text>
              {course.status !== "PENDING" && (
                <Text as={"i"} style={textStyle}>
                  Remark : {course.remark}
                </Text>
              )}
            </Flex>
          </Flex>
      </Flex>
        </Link>
      <Divider />
    </>
  );
};

export default CourseCard;
