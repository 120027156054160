import React from 'react'
import {Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, useToast} from '@chakra-ui/react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../store/user/userAction';

const ForgetPassword = ({profile}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        handleSubmit,
        register,
        formState: { errors },
      } = useForm();    
    const dispatch = useDispatch();
    const toast = useToast();
    const submitHandler = async(data) => {
        data.profile = profile;
        const result = await dispatch(forgotPassword(data));
        if(result.payload.success){toast({
            description: result.payload.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });}
        if(result.payload.errorMessage){toast({
            description: result.payload.errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          });}
        onClose();
    };
  return (
    <>
      <Text cursor={"pointer"} onClick={onOpen}>Forgot Password?</Text>

      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent backgroundColor={"transparent"} boxShadow={"none"}>
          <ModalBody>
              <Flex
                backgroundColor={"white"}
                boxShadow={"none"}
                padding={"25px 15px"}
                borderRadius={"15px"}
                flexDir={"column"}
                gap={5}
              >
                <Text as={'b'} fontSize={"lg"} fontFamily={"Outfit,sans-serif"}>Forget Password</Text>
                <form onSubmit={handleSubmit(submitHandler)} style={{width:"95%", display:"flex", flexDirection:"column", gap:10}}>
                    <FormControl>
                        <FormLabel>Email:</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder="Enter your e-mail..."
                    {...register("email", { required: "Email is required" })}
                    />
                  {errors.email && <p className="formError">{errors.email.message}</p>}
                    </FormControl>

                  <Button
                    type="submit"
                    colorScheme="blue"
                    borderRadius={"8px"}
                    bg={"#662D91"}
                    fontFamily={"Outfit"}
                    fontWeight={400}
                    alignSelf={"flex-end"}
                  >
                    Submit
                  </Button>
                </form>
              </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ForgetPassword