import { Avatar, Flex, Image, Text } from "@chakra-ui/react";
import starImage from "./../../images/course card rating star.svg"
import React from "react";

const ReviewCard = ({ review }) => {
  return (
    <Flex align={"flex-start"} gap={4}>
      <Avatar src={review.user?.image?.url} name={`${review.user.firstName} ${review.user.lastName}`} boxSize={"45px"} />
      <Flex flexDir={"column"}>
        <Flex align={"center"} minH={"45px"} fontFamily={"Outfit, sans-serif"} fontWeight={600} fontSize={"xl"} gap={2}>
          <Text>{review.user.firstName} {review.user.lastName}</Text>
          <Image src={starImage} alt='⭐' h={"18px"} w={"20px"} />
          <Text>{review.rating} / 5</Text>
        </Flex>
        <Text className="courseDescText">{review.comment}</Text>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
