import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import ReportHandler from "./ReportHandler";

const ReportCard = ({ report, textStyle }) => {
  const btnStyle = {
    color: "#FFFFFF",
    fontFamily: '"Outfit", sans-serif',
    fontSize: "18px",
    fontWeight: 400,
    padding: "8px 20px",
    borderRadius: "14px",
  };
  return (
    <Flex align={"flex-start"} justify={"space-between"}>
      <Link to={`/course/${report.course._id}`}>
        <Flex align={"flex-start"} gap={4}>
          <Image
            src={report.course.banner.url}
            alt={report.course.title}
            height={"80px"}
            w={"150px"}
          />
          <Flex flexDir={"column"} style={textStyle} gap={1}>
            <Text fontWeight={600} fontSize={"lg"} textTransform={"capitalize"} mt={0} pt={0}>{report.course.title}</Text>
            <Flex flexDir={"column"} gap={0} fontSize={"sm"}>
              <Text>Created by {report.creator.firstName} {report.creator.lastName}</Text>
              <Text><b>Category:</b> {report.category}</Text>
              <Text><b>Reason:</b> {report.reason}</Text>
              {report.remark && <Text><b>Remark:</b> {report.remark}</Text>}
            </Flex>
          </Flex>
        </Flex>
      </Link>
      {report.status === "PENDING" && (
        <ReportHandler btnStyle={btnStyle} report={report} textStyle={textStyle} />
      )}
    </Flex>
  );
};

export default ReportCard;
