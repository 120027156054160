import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  useDisclosure,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/react";
import { userLogout } from "../../store/user/userAction";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserMenu = ({btnStyle,overlayStyle,contentStyle}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const userLogoutHandler = ()=>{
    dispatch(userLogout());
    navigate("/");
    toast({
      title: "Logout:",
      description: "Successfully Logged Out",
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button className="menuText" style={btnStyle} onClick={onOpen}>Menu</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={overlayStyle}/>
        <ModalContent style={contentStyle}>
          <ModalBody>
            <Link to={"/user"}><Text onClick={()=>onClose()} className="menuText">Profile</Text></Link>
            <Divider/>
            <Link to={"/user/wishlist"}><Text onClick={()=>onClose()} className="menuText">Wishlist</Text></Link>
            <Divider/>
            <Text onClick={userLogoutHandler} className="menuText">Logout</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserMenu;
