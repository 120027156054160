import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { creatorCourses } from '../../store/creator/creatorAction';
import EmptyPage from './../EmptyPage';
import LoadingPage from './../LoadingPage'
import CourseCard from './../../components/course/CourseCard';

const CreatorCourses = () => {
    const {loading,courses} = useSelector((state)=>state.courses)
    const [status,setStatus]=useState("PUBLIC");
    const [message, setMessage] = useState("No course is publicily available");
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(creatorCourses({status:status}));
    },[dispatch,status])
    const style = {
        size:"md",
        fontFamily:'"Outfit",sans-serif',
        cursor:"pointer"
    }
  return (
    <Flex flexDir={"column"} justify={"center"} align={"center"} gap={15}>
        <Flex gap={5} wrap={"wrap"}>
            <Text onClick={()=>{setStatus("DRAFT");setMessage("No draft available")}} style={style} color={status === "DRAFT"?"#000":"gray"}>Drafts</Text>
            <Text onClick={()=>{setStatus("PENDING");setMessage("No course is submitted")}} style={style} color={status === "PENDING"?"#000":"gray"}>Submitted</Text>
            <Text onClick={()=>{setStatus("PUBLIC");setMessage("No course is publicily available")}} style={style} color={status === "PUBLIC"?"#000":"gray"}>Public</Text>
        </Flex>
        <Flex wrap={"wrap"} justify={"center"} gap={5} minH={"90vh"}>
            {loading ? <LoadingPage/>:courses?.length === 0? <EmptyPage message={message}/> : courses?.map((course)=>{return <CourseCard key={course._id} course={course}/>})}
        </Flex>
    </Flex>
  )
}

export default CreatorCourses