import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  useDisclosure,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLogout } from "../../store/admin/adminAction";

const AdminMenu = ({btnStyle,overlayStyle,contentStyle}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const logoutHandler = ()=>{
    dispatch(adminLogout());
    navigate("/");
    toast({
      title: "Logout:",
      description: "Successfully Logged Out",
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button className="menuText" style={btnStyle} onClick={onOpen}>Menu</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={overlayStyle}/>
        <ModalContent style={contentStyle}>
          <ModalBody>
            <Link to={"/admin"}><Text onClick={()=>onClose()} className="menuText">Profile</Text></Link>
            <Divider/>
            <Link to={"/admin/courses"}><Text onClick={()=>onClose()} className="menuText">Updated Course</Text></Link>
            <Divider/>
            <Link to={"/admin/reports"}><Text onClick={()=>onClose()} className="menuText">Student Reports</Text></Link>
            <Divider/>
            <Link to={"/admin/categories"}><Text onClick={()=>onClose()} className="menuText">Categories</Text></Link>
            <Divider/>
            <Text onClick={logoutHandler} className="menuText">Logout</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminMenu;
