import { createSlice } from '@reduxjs/toolkit';
import { fetchCategories } from '../admin/adminAction';

const initialState = {
  loading: false,
  error: null,
  categories: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.categories = action.payload.categories ? action.payload.categories : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, handleAsyncAction)
      .addCase(fetchCategories.rejected, handleAsyncAction)
  },
});

export default categoriesSlice.reducer;