import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminReports } from '../../store/admin/adminAction';
import LoadingPage from '../LoadingPage';
import ReportCard from './ReportCard';
import EmptyPage from '../EmptyPage';

const AdminReports = () => {
    const {loading,reports} = useSelector((state)=>state.reports)
    const [status,setStatus]=useState("PENDING");
    const [message, setMessage] = useState("No pending request");
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchAdminReports({status:status}));
    },[dispatch,status])
    const style = {
        size:"md",
        fontFamily:'"Outfit",sans-serif',
        cursor:"pointer"
    }
  return (
    <Flex flexDir={"column"} align={"center"} gap={15} w={"100%"}>
        <Flex gap={5} wrap={"wrap"}>
            <Text onClick={()=>{setStatus("PENDING");setMessage("No pending request")}} style={style} color={status === "PENDING"?"#000":"gray"}>PENDING</Text>
            <Text onClick={()=>{setStatus("APPROVED");setMessage("No approved request")}} style={style} color={status === "APPROVED"?"#000":"gray"}>APPROVED</Text>
            <Text onClick={()=>{setStatus("REJECTED");setMessage("No rejected request")}} style={style} color={status === "REJECTED"?"#000":"gray"}>REJECTED</Text>
        </Flex>
        <Flex flexDir={"column"} minH={"90vh"} w={"90%"} gap={8}>
            {loading && <LoadingPage/>}
            {reports?.length === 0 ? <EmptyPage message={message}/> : reports?.map((report)=>{return <ReportCard key={report._id} textStyle={style} report={report}/>})}
        </Flex>
    </Flex>
  )
}

export default AdminReports