import React from "react";
import { resetPassword } from "../../store/user/userAction";
import { Button, Flex, FormControl, FormLabel, Input, Text, useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
    const { profile,token } = useParams();
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const toast = useToast();
    const submitHandler = async (data) => {
        data.profile = profile;
        data.token = token;
        const result = await dispatch(resetPassword(data));
        if (result.payload.success) {
            toast({
                title:"Success",
                description: "Password Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate(`/${profile}`);
        }
        if (result.payload.errorMessage) {
            toast({
                description: result.payload.errorMessage,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };
    return (
        <Flex flexDir={"column"} w={"90vw"} maxW={"500px"} m={"auto"} justify={"center"} h={"70vh"} gap={6}> 
            <Text as={"b"} fontSize={"xl"} fontFamily={"Outfit,sans-serif"}>
                Reset Password
            </Text>
            <form
                onSubmit={handleSubmit(submitHandler)}
                style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                }}
            >
                <FormControl>
                    <FormLabel>Password:</FormLabel>
                    <Input
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Password"
                        {...register("password", { required: "Password is required" })}
                    />
                    {errors.password && <p className="formError">{errors.password.message}</p>}
                </FormControl>
                <FormControl>
                    <FormLabel>Confirm Password:</FormLabel>
                    <Input
                        type="password"
                        name="confirmPassword"
                        autoComplete="off"
                        placeholder="Confirm Password"
                        {...register("confirmPassword", { required: "Confirm Password is required" })}
                    />
                    {errors.confirmPassword && <p className="formError">{errors.confirmPassword.message}</p>}
                </FormControl>
                <Button
                    type="submit"
                    colorScheme="blue"
                    borderRadius={"8px"}
                    bg={"#662D91"}
                    fontFamily={"Outfit"}
                    fontWeight={400}
                    alignSelf={"flex-end"}
                >
                    Submit
                </Button>
            </form>
        </Flex>
    );
};

export default ResetPassword;
