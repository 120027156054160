import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { reportCourse } from "../../store/user/userAction";
import { GoReport } from "react-icons/go";

const CreateReport = ({ submitBtnStyle,btnStyle,courseId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    data["id"] = courseId;
    const result = await dispatch(reportCourse(data));
    if (result.payload.success) {
      toast({
        description: `Reported Successfully!`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose();
  };

  return (
    <>
      {/* <Button bg={"rgba(102, 45, 145, 1)"} style={btnStyle} onClick={onOpen}>
        Report
      </Button> */}
      <Flex onClick={onOpen} cursor={"pointer"} fontWeight={600} fontFamily={'"Inter",sans-serif'} align={"center"} gap={2} fontSize={"2xl"}><GoReport/><Text fontSize={"md"} color={"red"}>Report Course</Text></Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent>
          <ModalBody p={"30px"}>
            <Flex flexDir="column" gap={"15px"}>
              <Text className="reviewModalHeading">Report Course</Text>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Select
                  placeholder="Category"
                  {...register("category", {
                    required: "Category is required",
                  })}
                >
                  <option value="I own this course">I own this course</option>
                  <option value="It's spam">It's spam</option>
                  <option value="Nudity or sexual activity">Nudity or sexual activity</option>
                  <option value="Hate speech or symbols">Hate speech or symbols</option>
                  <option value="Violenceor dangerous organisations">Violenceor dangerous organisations</option>
                  <option value="Bullying or harassment">Bullying or harassment</option>
                  <option value="False information">False information</option>
                  <option value="Scam or fraud">Scam or fraud</option>
                  <option value="Suicide or self-injury">Suicide or self-injury</option>
                  <option value="Sale of illegal or regulated goods">Sale of illegal or regulated goods</option>
                  <option value="Intellectual property violation">Intellectual property violation</option>
                  <option value="Eating disorders">Eating disorders</option>
                  <option value="Something else">Something else</option>
                  <option value="Inappropriate Course Content">Inappropriate Course Content</option>
                  <option value="Inappropriate Behaviour">Inappropriate Behaviour</option>
                  <option value="Udemy Policy Violation">Udemy Policy Violation</option>
                  <option value="Spammy Content">Spammy Content</option>
                </Select>
                {errors.category && <Text color={"#FF3333"}>{errors.category.message}</Text>}

                <Textarea
                  pr="4.5rem"
                  type="text"
                  name="reason"
                  placeholder="Reason"
                  autoComplete="off"
                  mt={"15px"}
                  {...register("reason", {
                    required: "Reason is required",
                  })}
                />

                {errors.reason && <Text color={"#FF3333"}>{errors.reason.message}</Text>}

                <Button
                  mt={"20px"}
                  type="submit"
                  style={submitBtnStyle}
                >
                  Submit
                </Button>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateReport;
