import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ value,onChange,placeholder }) => {
  const [content, setContent] = useState(value);

  const handleChange = (value) => {
    setContent(value);
    onChange(value); // Pass the HTML content to the parent component
  };
  const toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    // ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    // [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    // [{ 'direction': 'rtl' }],
    // [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'color': [] }, { 'background': [] }],
    // [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']
  ];

  return (
    <div>
      <ReactQuill theme="snow" value={content} onChange={handleChange} modules={{ toolbar: toolbarOptions }} placeholder={placeholder}/>
    </div>
  );
};

export default TextEditor;
