import { createSlice } from '@reduxjs/toolkit';
import { adminGoogleLogin, adminLogin, adminLogout, adminRegister, getAdmin, updateAdmin } from './adminAction';

const initialState = {
  loading: false,
  error: null,
  admin: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    adminClearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.admin = action.payload.admin ? action.payload.admin : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminLogin.fulfilled, handleAsyncAction)
      .addCase(adminLogin.rejected, handleAsyncAction)

      .addCase(adminGoogleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminGoogleLogin.fulfilled, handleAsyncAction)
      .addCase(adminGoogleLogin.rejected, handleAsyncAction)

      .addCase(adminRegister.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminRegister.fulfilled, handleAsyncAction)
      .addCase(adminRegister.rejected, handleAsyncAction)
      
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdmin.fulfilled, handleAsyncAction)
      .addCase(updateAdmin.rejected, handleAsyncAction)

      .addCase(adminLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminLogout.fulfilled, handleAsyncAction)
      .addCase(adminLogout.rejected, handleAsyncAction)

      .addCase(getAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdmin.fulfilled, handleAsyncAction)
      .addCase(getAdmin.rejected, handleAsyncAction)
      
  },
});

export const { adminClearError } = adminSlice.actions;
export default adminSlice.reducer;