import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Radio, RadioGroup, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text, VStack, Input } from '@chakra-ui/react'
import React, { useState } from 'react'
import ReactStars from "react-rating-stars-component";
import { MdGraphicEq } from 'react-icons/md';
const buttonStyle = {
    fontFamily: '"Inter",sans-serif',
    fontWeight: 400
}
const optionStyle = {
    fontFamily: '"Inter",sans-serif',
    fontWeight: 200,
    paddingBottom: "18px",
}
const AccordionItemStyle = {
    border: 'none'
}
const Filters = ({ setFilters, filters }) => {
    const [priceRange, setPriceRange] = useState(filters.price ? filters.price : [0, 150000]);

    const handlePriceChange = (newPriceRange) => {
        setFilters((filters) => ({ ...filters, price: newPriceRange }));

    };
    const handleMinChange = (e) => {
        const minValue = parseInt(e.target.value);
        setPriceRange([minValue, priceRange[1]]);
        handlePriceChange([minValue, priceRange[1]]);
      };
    
      const handleMaxChange = (e) => {
        const maxValue = parseInt(e.target.value);
        setPriceRange([priceRange[0], maxValue]);
        handlePriceChange([priceRange[0], maxValue]);
      };
    return (
        <VStack align={"flex-start"}>
        <Heading fontSize={"20px"} ml={4}>Filters</Heading>
        <Accordion defaultIndex={[0]} allowToggle>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Sort By
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup name='sortOption' onChange={(value) => setFilters((filters) => ({ ...filters, sortOption: value }))} value={filters?.sortOption}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={'highestRating'}>Popularity</Radio>
                            <Radio value={'highestPrice'}>Price High to Low</Radio>
                            <Radio value={'lowestPrice'}>Price Low to High</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Ratings
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup name='rating' onChange={(value) => setFilters((filters) => ({ ...filters, rating: value }))} value={filters?.rating}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={'5'}><Flex align={"center"} lineHeight={"120%"}><ReactStars count={5} value={5} size={24} edit={false} activeColor="#ffd700" /><Text>(5)</Text></Flex></Radio>
                            <Radio value={'4'}><Flex align={"center"} lineHeight={"120%"}><ReactStars count={5} value={4} size={24} edit={false} activeColor="#ffd700" /><Text>(4)</Text></Flex></Radio>
                            <Radio value={'3'}><Flex align={"center"} lineHeight={"120%"}><ReactStars count={5} value={3} size={24} edit={false} activeColor="#ffd700" /><Text>(3)</Text></Flex></Radio>
                            <Radio value={'2'}><Flex align={"center"} lineHeight={"120%"}><ReactStars count={5} value={2} size={24} edit={false} activeColor="#ffd700" /><Text>(2)</Text></Flex></Radio>
                            <Radio value={'1'}><Flex align={"center"} lineHeight={"120%"}><ReactStars count={5} value={1} size={24} edit={false} activeColor="#ffd700" /><Text>(1)</Text></Flex></Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Price
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RangeSlider aria-label={['min', 'max']} value={[priceRange[0], priceRange[1]]} min={0} max={150000} onChangeEnd={(val) => handlePriceChange(val)} onChange={(value) => setPriceRange(value)}>
                        <RangeSliderTrack bg={"purple.100"}>
                            <RangeSliderFilledTrack bg={"#662D91"} />
                        </RangeSliderTrack>
                        <RangeSliderThumb boxSize={5} index={0}>
                            <Box color='#662D91' as={MdGraphicEq} />
                        </RangeSliderThumb>
                        <RangeSliderThumb boxSize={5} index={1}>
                            <Box color='#662D91' as={MdGraphicEq} />
                        </RangeSliderThumb>
                    </RangeSlider>
                    <Flex align={"center"} justify={"space-between"} gap={3}><Text>Min</Text><Input type='number' w={"70%"} value={priceRange[0]} onChange={(e) => handleMinChange(e)}/></Flex>
                    <Flex mt={3} align={"center"} justify={"space-between"} gap={2}><Text>Max</Text><Input type='number' w={"70%"} value={priceRange[1]} onChange={(e) => handleMaxChange(e)}/></Flex>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Level
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, courseLevel: value }))} value={filters?.courseLevel}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Beginner">Beginner</Radio>
                            <Radio value="Intermediate">Intermediate</Radio>
                            <Radio value="Expert">Expert</Radio>
                            <Radio value="All Levels">All Levels</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Program Type
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup name='programType' onChange={(value) => setFilters((filters) => ({ ...filters, programType: value }))} value={filters?.programType}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={"Cohort"}>Cohort</Radio>
                            <Radio value={"Courses"}>Courses</Radio>
                            <Radio value={"Degree"}>Degree</Radio>
                            <Radio value={"Masters"}>Masters</Radio>
                            <Radio value={"Doctorate"}>Doctorate</Radio>
                            <Radio value={"Training"}>Training</Radio>
                            <Radio value={"Study Abroad"}>Study Abroad</Radio>
                            <Radio value={"Workshops and Seminars"}>Workshops and Seminars</Radio>
                            <Radio value={"Conferences"}>Conferences</Radio>
                            <Radio value={"Masterclass"}>Masterclass</Radio>
                            <Radio value={"Bootcamps"}>Bootcamps</Radio>
                            <Radio value={"Seminars (Offline)"}>Seminars (Offline)</Radio>
                            <Radio value={"Webinars (Online)"}>Webinars (Online)</Radio>
                            <Radio value={"Appointments"}>Appointments</Radio>
                            <Radio value={"Assignment/Homework Help"}>Assignment/Homework Help</Radio>
                            <Radio value={"Personal Tutors"}>Personal Tutors</Radio>
                            <Radio value={"Books"}>Books</Radio>
                            <Radio value={"Newsletters"}>Newsletters</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Language
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, language: value }))} value={filters?.language}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={"English"}>English</Radio>
                            <Radio value={"Hindi"}>Hindi</Radio>
                            <Radio value={"Hinglish"}>Hinglish</Radio>
                            <Radio value={"Kannada"}>Kannada</Radio>
                            <Radio value={"Malayalam"}>Malayalam</Radio>
                            <Radio value={"Tamil"}>Tamil</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Course Duration
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, duration: value }))} value={filters?.duration}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={"0 - 1 Hour"}>0 - 1 Hour</Radio>
                            <Radio value={"1 - 3 Hour"}>1 - 3 Hour</Radio>
                            <Radio value={"3 - 6 Hours"}>3 - 6 Hours</Radio>
                            <Radio value={"6 - 17 Hours"}>6 - 17 Hours</Radio>
                            <Radio value={"17 Hours - 1 Day"}>17 Hours - 1 Day</Radio>
                            <Radio value={"1 - 15 Days"}>1 - 15 Days</Radio>
                            <Radio value={"Less Than 1 Month"}>Less Than 1 Month</Radio>
                            <Radio value={"1 - 3 Months"}>1 - 3 Months</Radio>
                            <Radio value={"3 - 6 Months"}>3 - 6 Months</Radio>
                            <Radio value={"6 Months +"}>6 Months +</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Certificate
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, certificate: value }))} value={filters?.certificate}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value={"With Free Certificate"}>With Free Certificate</Radio>
                            <Radio value={"With Certificate"}>With Certificate</Radio>
                            <Radio value={"With Degree"}>With Degree</Radio>
                            <Radio value={"No Certificate/Degree"}>No Certificate/Degree</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Learning mode
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, learningMode: value }))} value={filters?.learningMode}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Self Paced (Recorded)">Self Paced (Recorded)</Radio>
                            <Radio value="Instructor-led (Live)">Instructor-led (Live)</Radio>
                            <Radio value="Blended (Mix of Both)">Blended (Mix of Both)</Radio>
                            <Radio value="Not applicable">Not applicable</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Delivery Mode
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, deliveryMode: value }))} value={filters?.deliveryMode}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Online">Online</Radio>
                            <Radio value="In-Person (Offline)">In-Person (Offline)</Radio>
                            <Radio value="Hybrid (Mix of Both)">Hybrid (Mix of Both)</Radio>
                            <Radio value="Not applicable">Not applicable</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Commitment Type
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, courseType: value }))} value={filters?.courseType}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Online">Online</Radio>
                            <Radio value="Full-time">Full-time</Radio>
                            <Radio value="Part-time">Part-time</Radio>
                            <Radio value="Self Paced">Self Paced</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Placement Assistance
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, placementAssistance: value }))} value={filters?.placementAssistance}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Yes, Available">Yes, Available</Radio>
                            <Radio value="Not Available">Not Available</Radio>
                            <Radio value="Guaranteed">Guaranteed</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Work Experience
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, workExperience: value }))} value={filters?.workExperience}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Yes, Needed">Yes, Needed</Radio>
                            <Radio value="Not Needed">Not Needed</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Financing Options Availability
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, financeAvailability: value }))} value={filters?.financeAvailability}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Yes, Available">Yes, Available</Radio>
                            <Radio value="Not Available">Not Available</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem style={AccordionItemStyle}>
                <h2>
                    <AccordionButton style={buttonStyle}>
                        <Box as="span" flex='1' textAlign='left'>
                            Subtitles Availability
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel style={optionStyle}>
                    <RadioGroup onChange={(value) => setFilters((filters) => ({ ...filters, subtitles: value }))} value={filters?.subtitles}>
                        <VStack align={"flex-start"} ml={2}>
                            <Radio value="Yes, Available">Yes, Available</Radio>
                            <Radio value="Not Available">Not Available</Radio>
                        </VStack>
                    </RadioGroup>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
        </VStack>
    )
}
export default Filters