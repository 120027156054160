import { createSlice } from '@reduxjs/toolkit';
import { userLogin, userRegister, userLogout, getUser, getWishlist, updateUser, userGoogleLogin } from './userAction';

const initialState = {
  loading: false,
  error: null,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userClearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.user = action.payload.user ? action.payload.user : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, handleAsyncAction)
      .addCase(userLogin.rejected, handleAsyncAction)
      .addCase(userGoogleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userGoogleLogin.fulfilled, handleAsyncAction)
      .addCase(userGoogleLogin.rejected, handleAsyncAction)

      .addCase(userRegister.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userRegister.fulfilled, handleAsyncAction)
      .addCase(userRegister.rejected, handleAsyncAction)
      
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, handleAsyncAction)
      .addCase(updateUser.rejected, handleAsyncAction)

      .addCase(userLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogout.fulfilled, handleAsyncAction)
      .addCase(userLogout.rejected, handleAsyncAction)

      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, handleAsyncAction)
      .addCase(getUser.rejected, handleAsyncAction)
      
      .addCase(getWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWishlist.fulfilled, handleAsyncAction)
      .addCase(getWishlist.rejected, handleAsyncAction);
  },
});

export const { userClearError } = userSlice.actions;
export default userSlice.reducer;