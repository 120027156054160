import React, { useEffect, useState } from "react";
import { Button, Flex, Input, Text, FormLabel, FormControl, Heading, Avatar, Select, FormHelperText, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../components/text editor/TextEditor";
import { getUser, updateUser } from "../../store/user/userAction";
import LoadingPage from "../LoadingPage";
import { userClearError } from "../../store/user/user";

const UserProfile = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { loading,user } = useSelector((state) => state.user);
  const [updatedUser, setUpdatedUser] = useState({...user});

  const handleImageUpload = (e) => {
    const reader = new FileReader();
  
    reader.onload = () => {
      if (reader.readyState === 2) {
        setUpdatedUser((prevUser) => ({ ...prevUser, ["profileImage"]: reader.result }));
      }
    };
  
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };
  
  const handleDescription = (value) => {
    setUpdatedUser((prevUser) => ({ ...prevUser, ["description"]: value }));
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    const result = await dispatch(updateUser(updatedUser));
    if (result.payload.user) {
      toast({
        description: `Updated Successfully`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(userClearError());
    }
  };

  const userProfileStyle = {
    fontFamily:'"Outfit",sans-serif',
  }
  const formLabelStyle = {
    fontWeight : 600,
    margin : "0px"
  }
    useEffect(()=>{
      dispatch(getUser());
    },[])
  useEffect(()=>{
    setUpdatedUser({...user});
  },[user])
  return (
    loading?<LoadingPage/> : user && (
      <>
        <Flex align={"center"} p={"30px 5vw"} gap={5} bg={"linear-gradient(90deg, #FAFCFF 0%, rgba(102, 45, 145, 0.64) 62.29%, #662D91 100%)"} w={"100%"}>
          <Avatar src={user.image?.url} boxSize={"150px"}/>
          <Flex flexDir={"column"} color={"#662D91"}>
            <Heading as={"h2"} size={"lg"}>{user.firstName} {user.lastName}</Heading>
            <Text size={"lg"}>{user.profession}</Text>
          </Flex>
        </Flex>
      <Flex flexDir={"column"} align={"center"} p={"30px 5vw"} gap={5}>
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>Email</FormLabel>
              <Input
                type="email"
                disabled={true}
                value={updatedUser.email}
              />
              </FormControl>
              <FormControl style={userProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>First Name</FormLabel>
              <Input
                type="text"
                name="firstName"
                value={updatedUser.firstName}
                onChange={handleChange}
                placeholder="Enter your First Name"
              />
              </FormControl>
              <FormControl style={userProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>Last Name</FormLabel>
              <Input
                type="text"
                name="lastName"
                value={updatedUser.lastName}
                onChange={handleChange}
                placeholder="Enter your Last Name"
              />
              </FormControl>
              <FormControl style={userProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>Contact Number</FormLabel>
              <Input
                type="number"
                name="contactNumber"
                value={updatedUser.contactNumber}
                onChange={handleChange}
                placeholder="Enter your contact Number"
              />
              </FormControl>
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>Education Level</FormLabel>
              <Select
                  placeholder="Select Education level"
                  name="educationLevel"
                  onChange={handleChange}
                  value={updatedUser.educationLevel}
                >
                  <option value="Doctrate/ PhD">Doctrate/ PhD</option>
                  <option value="Pursuing Doctrate/Phd">Pursuing Doctrate/Phd</option>
                  <option value="Masters">Masters</option>
                  <option value="Pursuing Masters">Pursuing Masters</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Pursuing Graduation">Pursuing Graduation</option>
                  <option value="High Secondary Education (12th Passed)">High Secondary Education (12th Passed)</option>
                  <option value="Pursuing High Secondary Education (11th or 12th Class)">Pursuing High Secondary Education (11th or 12th Class)</option>
                  <option value="Secondary Education (10th Passed)">Secondary Education (10th Passed)</option>
                  <option value="Pursuing Secondary Education (9th or 10th Class)">Pursuing Secondary Education (9th or 10th Class)</option>
                  <option value="Primary Education (8th Class or Below)">Primary Education (8th Class or Below)</option>
                </Select>
              </FormControl>
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>Profile Image</FormLabel>
              <input type="file" accept="image/*" onChange={handleImageUpload} />
              <FormHelperText mt={0}>200px*200px is recommended size</FormHelperText>
              </FormControl>
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>About</FormLabel>
              <TextEditor
                value={updatedUser.description}
                onChange={handleDescription}
                placeholder={"Enter your description"}
              />
              </FormControl>
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>Location Pincode</FormLabel>
              <Input
                type="number"
                name="location"
                value={updatedUser.location}
                onChange={handleChange}
                placeholder="Enter your location pincode"
              />
              </FormControl>
              <FormControl style={userProfileStyle}>
              <FormLabel style={formLabelStyle}>Profession</FormLabel>
              <Input
                type="text"
                name="profession"
                value={updatedUser.profession}
                onChange={handleChange}
                placeholder="Enter your profession"
              />
              </FormControl>
              
              <Button
                alignSelf={"end"}
                type="submit"
                colorScheme="blue"
                mt={3}
                borderRadius={"8px"}
                bg={"#662D91"}
                fontFamily={"Outfit"}
                fontWeight={400}
                w={"100px"}
                disabled={loading}
              >
                {!loading?"Update":"Updating"}
              </Button>
            </form>
          </Flex>
          </>
    )
  );
};

export default UserProfile;
