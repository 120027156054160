import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { userClearError } from "./user/user";
import { creatorClearError } from "./creator/creator";
import { getCreator } from "./creator/creatorAction";
import { getUser } from "./user/userAction";
import { getAdmin } from "./admin/adminAction";
import { adminClearError } from "./admin/admin";

export const checkAuth = createAsyncThunk(
  "checkAuth",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      await dispatch(getUser());
      await dispatch(getCreator());
      await dispatch(getAdmin());
      dispatch(userClearError());
      dispatch(creatorClearError());
      dispatch(adminClearError());
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL+"/api/v1",
  withCredentials:true
});

export const applicationConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const multiConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
