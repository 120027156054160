import React from "react";
import { Divider, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CourseCard = ({ course, textStyle, close }) => {
  return (
    <>
      <Link to={`/course/${course._id}`} style={{ width: "100%" }} onClick={()=>{close()}}>
        <Flex align={"center"} p={3} gap={4} borderRadius={"15px"} _hover={{bg:"gray.100"}}>
          <Image
            src={course.banner.url}
            alt={course.title}
            height={"50px"}
            w={"100px"}
          />
          <Flex flexDir={"column"}>
            <Text fontWeight={600} style={textStyle}>
              {course.title}
            </Text>
            <Text style={textStyle}>
              {course.creator.firstName} {course.creator.lastName}
            </Text>
          </Flex>
        </Flex>
      </Link>
      <Divider w={"90%"} m={"auto"} />
    </>
  );
};

export default CourseCard;
