import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminCourses } from '../../store/admin/adminAction';
import CourseCard from './CourseCard';
import EmptyPage from './../EmptyPage';
import LoadingPage from './../LoadingPage'

const AdminCourses = () => {
    const {loading,courses} = useSelector((state)=>state.courses)
    const [status,setStatus] = useState("PUBLIC");
    const [message, setMessage] = useState("No course is publicily available");
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchAdminCourses({status:status}));
    },[dispatch,status])
    const style = {
        size:"md",
        fontFamily:'"Outfit",sans-serif',
        cursor:"pointer"
    }
  return (
    <Flex flexDir={"column"} align={"center"} gap={15} w={"100%"}>
        <Flex gap={5} wrap={"wrap"}>
            <Text onClick={()=>{setStatus("PENDING");setMessage("No Requests")}} style={style} color={status === "PENDING"?"#000":"gray"}>Requests</Text>
            <Text onClick={()=>{setStatus("PUBLIC");setMessage("No course is publicily available")}} style={style} color={status === "PUBLIC"?"#000":"gray"}>Public</Text>
            <Text onClick={()=>{setStatus("REJECTED");setMessage("No Rejected Courses")}} style={style} color={status === "REJECTED"?"#000":"gray"}>Rejected</Text>
        </Flex>
        <Flex flexDir={"column"} minH={"90vh"} w={"90%"}>
            {loading && <LoadingPage/>}
            {courses?.length === 0? <EmptyPage message={message}/> : courses?.map((course)=>{return <CourseCard key={course._id} course={course}/>})}
        </Flex>
    </Flex>
  )
}

export default AdminCourses