import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Heading, Icon, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../store/admin/adminAction';
import { MdKeyboardArrowRight } from "react-icons/md";
const textStyle = {
    fontFamily: '"Inter",sans-serif',
    fontWeight: "400",
    fontSize: "17px",
    marginBottom: "10px"
}
const NavbarDrawer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef()
    return (
        <>
            <RxHamburgerMenu ref={btnRef} onClick={onOpen} fontSize={"20px"} fontWeight={"bold"} />
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody my={10}>
                        <Link to={"/"}><Text style={textStyle} onClick={() => onClose()}>Home</Text></Link>
                        <Link to={"/about"}><Text style={textStyle} onClick={() => onClose()}>About Us</Text></Link>
                        <CategoriesDrawer navbarClose={onClose} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default NavbarDrawer

const CategoriesDrawer = ({ navbarClose }) => {
    const { categories } = useSelector((state) => state.categories);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openCategory, setOpenCategory] = useState(null);

    const toggleCategory = (e, category, index) => {
        if (e.target.classList.contains('categoryLink')) {
            navigate(`/learn/${category.urlName}`);
            onClose();
            navbarClose();
        } else if (e.target.classList.contains('dropDownButton')) {
            setOpenCategory(openCategory === index ? null : index);
        }
    };
    const handleSubCategory = (subCategory, category) => {
        navigate(`/learn/${category.urlName}/${subCategory.urlName}`);
        onClose();
        navbarClose();
    }
    useEffect(() => {
        dispatch(fetchCategories());
    }, [])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnReference = React.useRef()
    return (
        <>
            <Text ref={btnReference} onClick={onOpen} style={textStyle} cursor={"pointer"}>Categories</Text>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnReference}
            >
                <DrawerOverlay />
                <DrawerContent>
                    {/* <DrawerCloseButton /> */}
                    <DrawerBody my={4}>
                        <Text fontFamily={'"Inter",sans-serif'} fontSize={"xl"} fontWeight={600} mb={6}>Categories</Text>
                        {categories && categories.map((category, index) => (
                            <div key={index} onClick={(e) => toggleCategory(e, category, index)} fontFamily={'"Inter",sans-serif'} style={{ cursor: 'pointer' }}>
                                <Flex align="center" justify={"space-between"} fontWeight={500}>
                                    <Text style={textStyle} className='categoryLink' flex={1}>{category.name}</Text>
                                    <MdKeyboardArrowRight className='dropDownButton' />
                                </Flex>
                                <Collapse in={openCategory === index}>
                                    <VStack ml={4} gap={2} align={"flex-start"} mb={4} fontWeight={300}>
                                        {category.subCategories.map((subCategory, subIndex) => (
                                            <Text key={subIndex} onClick={() => handleSubCategory(subCategory, category)}>{subCategory.name}</Text>
                                        ))}
                                    </VStack>
                                </Collapse>
                            </div>
                        ))}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}