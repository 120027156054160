import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminReports } from '../admin/adminAction';

const initialState = {
  loading: false,
  error: null,
  reports: null,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reportsClearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.reports = action.payload.reports ? action.payload.reports : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(fetchAdminReports.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdminReports.fulfilled, handleAsyncAction)
      .addCase(fetchAdminReports.rejected, handleAsyncAction)
  },
});

export const { reportsClearError } = reportsSlice.actions;
export default reportsSlice.reducer;