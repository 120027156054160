import React, { useEffect } from 'react'
import { Flex, Image } from '@chakra-ui/react'
import CourseCard from '../components/course/CourseCard'
import { fetchRandomCourses } from '../store/user/userAction'
import { useDispatch, useSelector } from 'react-redux'
import landingImg from "./../images/landingImg.jpg"
const LandingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRandomCourses());
  }, [dispatch]);
  const courses = useSelector((state)=>state.courses.courses)
  return (
    <>
    <Image src={landingImg} alt={`Wezlaa`} px={3} maxH={"60dvh"} h={"auto"} w={"100dvw"} fit={"contain"}/>
    <Flex justify={"center"} gap={4} wrap={"wrap"} mb={10} px={5} w={"100dvw"} mx={"auto"}>
      {courses?.map((course)=>{return <CourseCard key={course._id} course={course}/>})}
    </Flex>
    </>
  )
}

export default LandingPage