import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SecureRoute = ({ profile }) => {
  const user = useSelector((state) => state.user);
  const creator = useSelector((state) => state.creator);
  const admin = useSelector((state) => state.admin);
  const isAuthorized = (profile) => {
    switch (profile) {
      case "user":
        return user && user.user;
      case "creator":
        return creator && creator.creator;
      case "admin":
        return admin && admin.admin;
      default:
        return false;
    }
  };

  return isAuthorized(profile) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default SecureRoute;
