import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createCourse } from "./../../store/creator/creatorAction";
import TextEditor from "../../components/text editor/TextEditor";
import { fetchCategories } from "../../store/admin/adminAction";
const CreateCourse = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);
  const { categories } = useSelector((state) => state.categories);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [description, setDescription] = useState(null);
  const handleDescription = (content) => {
    setDescription(content);
  };
  const [preRequisite, setPreRequisite] = useState(null);
  const handlePreRequisite = (content) => {
    setPreRequisite(content);
  };
  const [outcome, setOutcome] = useState(null);
  const handleOutcome = (content) => {
    setOutcome(content);
  };
  const [submitStatus, setSubmitStatus] = useState("draft");

  const [banner, setBanner] = useState(null);
  const handleBannerUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setBanner(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  const toast = useToast();
  const [loading, setLoading]= useState(false);
  const submitHandler = async (data) => {
    setLoading(true);
    data["description"] = description;
    data["preRequisite"] = preRequisite;
    data["outcome"] = outcome;
    data.category = selectedCategory;
    data.subCategory = selectedSubcategory;
    data.bannerImage = banner;
    if (submitStatus === "publish") {
      data.status = "PENDING";
    }
    const result = await dispatch(createCourse(data));
    if (result.payload.success) {
      toast({
        description: `"${result.payload.course.title}" course created Successfully`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      onClose();
      onClose();
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };
  const fontStyle = {
    fontFamily: '"Outfit",sans-serif',
  };
  const formLabelStyle = {
    fontWeight: 600,
    marginBottom: "6px"
  };
  return (
    <>
      <Text className="menuText" onClick={onOpen}>
        New Course
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent>
          <ModalBody style={fontStyle}>
            <form
              onSubmit={handleSubmit(submitHandler)}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: 10,
              }}
            >
              <Heading textAlign={"center"} as={"h3"} size={"lg"}>
                Create Course
              </Heading>
              <FormControl>
                <FormLabel style={formLabelStyle}>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  autoComplete="off"
                  placeholder="Title"
                  {...register("title", { required: "Title is required" })}
                />
                {errors.title && (
                  <p className="formError">{errors.title.message}</p>
                )}
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Description</FormLabel>
                <TextEditor
                  onChange={handleDescription}
                  placeholder={"Enter course Description"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Pre Requisite</FormLabel>
                <TextEditor
                  onChange={handlePreRequisite}
                  placeholder={"Enter course Pre Requisite"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Outcome</FormLabel>
                <TextEditor
                  onChange={handleOutcome}
                  placeholder={"Enter course Outcome"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Banner Image</FormLabel>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBannerUpload}
                />
                <FormHelperText mt={0}>390px*210px is recommended size</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Program Type</FormLabel>
                <Select
                  placeholder="Select Program Type"
                  {...register("programType", {
                    required: "Select Program Type",
                  })}
                >
                  <option value={"Cohorts"}>Cohorts</option>
                  <option value={"Course"}>Course</option>
                  <option value={"Degree"}>Degree</option>
                  <option value={"Masters"}>Masters</option>
                  <option value={"Doctorate"}>Doctorate</option>
                  <option value={"Training"}>Training</option>
                  <option value={"Study Abroad"}>Study Abroad</option>
                  <option value={"Workshops and Seminars"}>Workshops and Seminars</option>
                  <option value={"Conferences"}>Conferences</option>
                  <option value={"Masterclass"}>Masterclass</option>
                  <option value={"Bootcamp"}>Bootcamp</option>
                  <option value={"Seminar (Offline)"}>Seminar (Offline)</option>
                  <option value={"Webinar (Online)"}>Webinar (Online)</option>
                  <option value={"Appointment"}>Appointment</option>
                  <option value={"Assignment/Homework Help"}>Assignment/Homework Help</option>
                  <option value={"Personal Tutor"}>Personal Tutor</option>
                  <option value={"Book"}>Book</option>
                  <option value={"Newsletter"}>Newsletter</option>
                </Select>

              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Category</FormLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  placeholder="Select Category"
                >
                  {categories?.map((category) => (
                    <option key={category._id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedCategory && <FormControl>
                <FormLabel style={formLabelStyle}>Subcategory</FormLabel>
                <Select
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  placeholder="Select Sub-Category"
                >
                  {categories
                    ?.find((category) => category.name === selectedCategory)
                    ?.subCategories?.map((subcategory) => (
                      <option key={subcategory} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                </Select>
              </FormControl>}

              <FormControl>
                <FormLabel style={formLabelStyle}>Language</FormLabel>
                <Select
                  placeholder="Select language"
                  {...register("language", {
                    required: "Select the language",
                  })}
                >
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Hinglish">Hinglish</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Tamil">Tamil</option>
                </Select>
                {errors.language && (
                  <p className="formError">{errors.language.message}</p>
                )}
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Duration</FormLabel>
                <Select
                  placeholder="Select duration"
                  {...register("duration")}
                >
                  <option value="0 - 1 Hour">0 - 1 Hour</option>
                  <option value="1 - 3 Hour">1 - 3 Hour</option>
                  <option value="3 - 6 Hours">3 - 6 Hours</option>
                  <option value="6 - 17 Hours">6 - 17 Hours</option>
                  <option value="17 Hours - 1 Day">17 Hours - 1 Day</option>
                  <option value="1 - 15 Days">1 - 15 Days</option>
                  <option value="Less Than 1 Month">Less Than 1 Month</option>
                  <option value="1 - 3 Months">1 - 3 Months</option>
                  <option value="3 - 6 Months">3 - 6 Months</option>
                  <option value="6 Months +">6 Months +</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Certificate</FormLabel>
                <Select
                  placeholder="Select course certification"
                  {...register("certificate")}
                >
                  <option value="With Free Certificate">With Free Certificate</option>
                  <option value="With Certificate">With Certificate</option>
                  <option value="With Degree">With Degree</option>
                  <option value="No Certificate/Degree">No Certificate/Degree</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Levels</FormLabel>
                <Select
                  placeholder="Select Level"
                  {...register("courseLevel")}
                >
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Expert">Expert</option>
                  <option value="All Levels">All Levels</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Learning Mode</FormLabel>
                <Select
                  placeholder="Select Learning Mode"
                  {...register("learningMode")}
                >
                  <option value="Self Paced (Recorded)">Self Paced (Recorded)</option>
                  <option value="Instructor-led (Live)">Instructor-led (Live)</option>
                  <option value="Blended (Mix of Both)">Blended (Mix of Both)</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Delivery Mode</FormLabel>
                <Select
                  placeholder="Select Delivery Mode"
                  {...register("deliveryMode")}
                >
                  <option value="Online">Online</option>
                  <option value="In-Person (Offline)">In-Person (Offline)</option>
                  <option value="Hybrid (Mix of Both)">Hybrid (Mix of Both)</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Commitment Type</FormLabel>
                <Select
                  placeholder="Select Commitment Type"
                  {...register("courseType")}
                >
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Self Paced">Self Paced</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Placement Assistance</FormLabel>
                <Select
                  placeholder="Select Placement Assistance"
                  {...register("placementAssistance")}
                >
                  <option value="Yes, Available">Yes, Available</option>
                  <option value="Not Available">Not Available</option>
                  <option value="Guaranteed">Guaranteed</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Start Date</FormLabel>
                <Input
                  type="Date"
                  {...register("startDate", {
                    required: "Select Starting Date",
                  })}
                />
                {errors.startDate && (
                  <p className="formError">{errors.startDate.message}</p>
                )}
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Price</FormLabel>
                <Input
                  type="Number"
                  name="price"
                  autoComplete="off"
                  placeholder="Price"
                  {...register("price", {
                    required: "Price is required",
                  })}
                />
                {errors.price && (
                  <p className="formError">{errors.price.message}</p>
                )}
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Link</FormLabel>
                <Input
                  type="url"
                  name="url"
                  autoComplete="off"
                  placeholder="Link"
                  {...register("url", { required: "Link is required" })}
                />
                <FormHelperText>Enter complete url (https://yourdomain.com)</FormHelperText>
                {errors.url && (
                  <p className="formError">{errors.url.message}</p>
                )}
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Work Experience</FormLabel>
                <Select
                  placeholder="Select Work Experience"
                  {...register("workExperience")}
                >
                  <option value="Yes, Needed">Yes, Needed</option>
                  <option value="Not Needed">Not Needed</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Finance Availability</FormLabel>
                <Select
                  placeholder="Select Finance Availability"
                  {...register("financeAvailability")}
                >
                  <option value="Yes, Available">Yes, Available</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Subtitles Availability</FormLabel>
                <Select
                  placeholder="Select Subtitles Availability"
                  {...register("subtitles")}
                >
                  <option value="Yes Available">Yes Available</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              </FormControl>
              <Flex justify={"end"} gap={5}>
                <Button
                  type="submit"
                  onClick={() => setSubmitStatus("draft")}
                  borderRadius={"8px"}
                  fontFamily={"Outfit"}
                  fontWeight={400}
                  bg={"gray.200"}
                  color={"#000000"}
                  isDisabled={!preRequisite || !description || !outcome || !banner || loading}
                >
                  Save Draft
                </Button>
                <Button
                  type="submit"
                  onClick={() => setSubmitStatus("publish")}
                  borderRadius={"8px"}
                  fontFamily={"Outfit"}
                  fontWeight={400}
                  bg={"#662D91"}
                  color={"#FFFFFF"}
                  isDisabled={!preRequisite || !description || !outcome || !banner || loading}
                >
                  Publish
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCourse;
