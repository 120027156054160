import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateCourse } from "../../store/creator/creatorAction";
import TextEditor from "../../components/text editor/TextEditor";
const UpdateCourse = ({ btnStyle, course }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [updatedCourse, setUpdatedCourse] = useState({ ...course });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, [name]: value }));
  };

  const dispatch = useDispatch();

  const [description, setDescription] = useState(course.description);
  const handleDescription = (content) => {
    setDescription(content);
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, ["description"]: content }));
  };
  const [preRequisite, setPreRequisite] = useState(course.preRequisite);
  const handlePreRequisite = (content) => {
    setPreRequisite(content);
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, ["preRequisite"]: content }));
  };
  const [outcome, setOutcome] = useState(course.outcome);
  const handleOutcome = (content) => {
    setOutcome(content);
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, ["outcome"]: content }));
  };

  const handleBannerUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUpdatedCourse((prevCourse) => ({ ...prevCourse, ["bannerImage"]: reader.result }));
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const toast = useToast();
  const submitHandler = async () => {
    const result = await dispatch(updateCourse(updatedCourse));
    if (result.payload.success) {
      toast({
        description: `"${result.payload.course.title}" course Updated Successfully`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onClose();
  };
  const fontStyle = {
    fontFamily: '"Outfit",sans-serif',
  };
  const formLabelStyle = {
    fontWeight: 600,
    margin: "0px",
    marginBottom: "6px"
  };
  const { categories } = useSelector((state) => state.categories);
  const [selectedCategory, setSelectedCategory] = useState(updatedCourse.category);
  const [selectedSubcategory, setSelectedSubcategory] = useState(updatedCourse.subCategory);
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, category: event.target.value }));
  };
  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
    setUpdatedCourse((prevCourse) => ({ ...prevCourse, subCategory: event.target.value }));
  };
  return (
    <>
      <Button style={btnStyle} onClick={onOpen}>
        Update Course
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent>
          <ModalBody style={fontStyle}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: 10,
                margin: 2
              }}
            >
              <Heading textAlign={"center"} as={"h3"} size={"lg"}>
                Create Course
              </Heading>
              <FormControl>
                <FormLabel style={formLabelStyle}>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  autoComplete="off"
                  placeholder="Title"
                  value={updatedCourse.title}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Description</FormLabel>
                <TextEditor
                  onChange={handleDescription}
                  value={updatedCourse.description}
                  placeholder={"Enter course Description"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Pre Requisite</FormLabel>
                <TextEditor
                  onChange={handlePreRequisite}
                  value={updatedCourse.preRequisite}
                  placeholder={"Enter course Pre Requisite"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Outcome</FormLabel>
                <TextEditor
                  onChange={handleOutcome}
                  value={updatedCourse.outcome}
                  placeholder={"Enter course Outcome"}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Banner Image</FormLabel>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBannerUpload}
                />
                <FormHelperText mt={0}>390px*210px is recommended size</FormHelperText>
                <Image src={updatedCourse?.banner?.url} alt={course.title} h={"210px"} w={"390px"}/>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Category</FormLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  placeholder="Select Category"
                >
                  {categories?.map((category) => (
                    <option key={category._id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {selectedCategory && <FormControl>
                <FormLabel style={formLabelStyle}>Subcategory</FormLabel>
                <Select
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  placeholder="Select Sub-Category"
                >
                  {categories
                    ?.find((category) => category.name === selectedCategory)
                    ?.subCategories?.map((subcategory) => (
                      <option key={subcategory} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                </Select>
              </FormControl>}

              <FormControl>
                <FormLabel style={formLabelStyle}>Program Type</FormLabel>
                <Select
                  name="programType"
                  placeholder="Select Program Type"
                  value={updatedCourse.programType}
                  onChange={handleChange}
                >
                  <option value={"Cohorts"}>Cohorts</option>
                  <option value={"Course"}>Course</option>
                  <option value={"Degree"}>Degree</option>
                  <option value={"Masters"}>Masters</option>
                  <option value={"Doctorate"}>Doctorate</option>
                  <option value={"Training"}>Training</option>
                  <option value={"Study Abroad"}>Study Abroad</option>
                  <option value={"Workshops and Seminars"}>Workshops and Seminars</option>
                  <option value={"Conferences"}>Conferences</option>
                  <option value={"Masterclass"}>Masterclass</option>
                  <option value={"Bootcamp"}>Bootcamp</option>
                  <option value={"Seminar (Offline)"}>Seminar (Offline)</option>
                  <option value={"Webinar (Online)"}>Webinar (Online)</option>
                  <option value={"Appointment"}>Appointment</option>
                  <option value={"Assignment/Homework Help"}>Assignment/Homework Help</option>
                  <option value={"Personal Tutor"}>Personal Tutor</option>
                  <option value={"Book"}>Book</option>
                  <option value={"Newsletter"}>Newsletter</option>
                </Select>

              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Language</FormLabel>
                <Select
                  placeholder="Select language"
                  name="language"
                  value={updatedCourse.language}
                  onChange={handleChange}
                >
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Hinglish">Hinglish</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Tamil">Tamil</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Duration</FormLabel>
                <Select
                  name="duration"
                  placeholder="Select duration"
                  value={updatedCourse.duration}
                  onChange={handleChange}
                >
                  <option value="0 - 1 Hour">0 - 1 Hour</option>
                  <option value="1 - 3 Hour">1 - 3 Hour</option>
                  <option value="3 - 6 Hours">3 - 6 Hours</option>
                  <option value="6 - 17 Hours">6 - 17 Hours</option>
                  <option value="17 Hours - 1 Day">17 Hours - 1 Day</option>
                  <option value="1 - 15 Days">1 - 15 Days</option>
                  <option value="Less Than 1 Month">Less Than 1 Month</option>
                  <option value="1 - 3 Months">1 - 3 Months</option>
                  <option value="3 - 6 Months">3 - 6 Months</option>
                  <option value="6 Months +">6 Months +</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Certificate</FormLabel>
                <Select
                  name="certificate"
                  placeholder="Select course certification"
                  value={updatedCourse.certificate}
                  onChange={handleChange}
                >
                  <option value="With Free Certificate">With Free Certificate</option>
                  <option value="With Certificate">With Certificate</option>
                  <option value="With Degree">With Degree</option>
                  <option value="No Certificate/Degree">No Certificate/Degree</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Levels</FormLabel>
                <Select
                  name="courseLevel"
                  placeholder="Select Level"
                  value={updatedCourse.courseLevel}
                  onChange={handleChange}
                >
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Expert">Expert</option>
                  <option value="All Levels">All Level</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Learning Mode</FormLabel>
                <Select
                  placeholder="Select Learning Mode"
                  value={updatedCourse.learningMode}
                  onChange={handleChange}
                  name="learningMode"
                >
                  <option value="Self Paced (Recorded)">Self Paced (Recorded)</option>
                  <option value="Instructor-led (Live)">Instructor-led (Live)</option>
                  <option value="Blended (Mix of Both)">Blended (Mix of Both)</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Delivery Mode</FormLabel>
                <Select
                  placeholder="Select Delivery Mode"
                  value={updatedCourse.deliveryMode}
                  onChange={handleChange}
                  name="deliveryMode"
                >
                  <option value="Online">Online</option>
                  <option value="In-Person (Offline)">In-Person (Offline)</option>
                  <option value="Hybrid (Mix of Both)">Hybrid (Mix of Both)</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Commitment Type</FormLabel>
                <Select
                  placeholder="Select Commitment Type"
                  value={updatedCourse.courseType}
                  onChange={handleChange}
                  name="courseType"
                >
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Self Paced">Self Paced</option>
                  <option value="Not applicable">Not applicable</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Placement Assistance</FormLabel>
                <Select
                  placeholder="Select Placement Assistance"
                  value={updatedCourse.placementAssistance}
                  onChange={handleChange}
                  name="placementAssistance"
                >
                  <option value="Yes, Available">Yes, Available</option>
                  <option value="Not Available">Not Available</option>
                  <option value="Guaranteed">Guaranteed</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Start Date</FormLabel>
                <Input
                  type="Date"
                  value={updatedCourse.startDate}
                  onChange={handleChange}
                  name="startDate"
                />
              </FormControl>

              <FormControl>
                <FormLabel style={formLabelStyle}>Price</FormLabel>
                <Input
                  type="Number"
                  name="price"
                  placeholder="Price"
                  value={updatedCourse.price}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Link</FormLabel>
                <Input
                  type="url"
                  name="url"
                  value={updatedCourse.url}
                  onChange={handleChange}
                  placeholder="Link"
                />
                <FormHelperText>Enter complete url (https://yourdomain.com)</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Work Experience</FormLabel>
                <Select
                  placeholder="Select Work Experience"
                  name="workExperience"
                  value={updatedCourse.workExperience}
                  onChange={handleChange}
                >
                  <option value="Yes, Needed">Yes, Needed</option>
                  <option value="Not Needed">Not Needed</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Finance Availability</FormLabel>
                <Select
                  placeholder="Select Finance Availability"
                  name="financeAvailability"
                  value={updatedCourse.financeAvailability}
                  onChange={handleChange}
                >
                  <option value="Yes, Available">Yes, Available</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel style={formLabelStyle}>Subtitles Availability</FormLabel>
                <Select
                  placeholder="Select Subtitles Availability"
                  name="subtitles"
                  value={updatedCourse.subtitles}
                  onChange={handleChange}
                >
                  <option value="Yes Available">Yes Available</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              </FormControl>
              <Flex justify={"end"} gap={5}>
                <Button
                  onClick={()=>{submitHandler()}}
                  type="submit"
                  borderRadius={"8px"}
                  fontFamily={"Outfit"}
                  fontWeight={400}
                  bg={"#662D91"}
                  color={"#FFFFFF"}
                  isDisabled={!preRequisite || !description || !outcome }
                >
                  Update
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateCourse;
