import React from 'react'
import logo from './../../images/Wezlaa logo.png'
import { Divider, Flex, Image, Text } from '@chakra-ui/react'
import instagramIcon from "./../../images/instagram-icon.png"
import linkedInIcon from "./../../images/linkedIn-icon.png"
import facebookIcon from "./../../images/facebook-icon.png"
import whatsappIcon from "./../../images/whatsapp-icon.png"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <Flex flexDir={"column"} align={"center"} gap={2} px={5} py={2}>
      <Image src={logo} alt='WEZLAA' h={"40px"} />
      <Flex align={"center"} gap={4}>
        <Link to={"/"}><Text cursor={"pointer"}>Home</Text></Link>
        <Link to={"/about"}><Text cursor={"pointer"}>About Us</Text></Link>
      </Flex>
      <Flex align={"center"} gap={4}>
        <a href='https://www.linkedin.com/company/wezlaa' target='_blank'><Image src={linkedInIcon} alt='linkedIn' h={"30px"} cursor={"pointer"} /></a>
        <a href='https://www.facebook.com/profile.php?id=61556359343431&mibextid=ZbWKwL' target='_blank'><Image src={facebookIcon} alt='facebook' h={"30px"} cursor={"pointer"} /></a>
        <a href='https://www.instagram.com/wezlaa.ed?igsh=MXZ2bDMyNXBjOGlyZg==' target='_blank'><Image src={instagramIcon} alt='instagram' h={"30px"} cursor={"pointer"} /></a>
        <a href='http://wa.me/+918283087757' target='_blank'><Image src={whatsappIcon} alt='whatsapp' h={"30px"} cursor={"pointer"} /></a>
      </Flex>
      <Divider borderColor={"#000000"} />
      <Text>© 2024 Wezlaa, All rights reserved.</Text>
    </Flex>
  )
}

export default Footer