import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { reportHandler } from "../../store/admin/adminAction";

const ReportHandler = ({ btnStyle, report, textStyle }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status,setStatus] = useState("APPROVED");
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const onSubmit = async(data) => {
    data["reportId"] = report._id;
    data["status"] = status;
    const result = await dispatch(reportHandler(data));
        if(result.payload.success){toast({
            description: status==="APPROVED"?"Report Accepted":"Report Rejected", // Adjust based on your user data structure
            status: "success",
            duration: 3000,
            isClosable: true,
          });}
        if(result.payload.errorMessage){toast({
            description: result.payload.errorMessage, // Adjust based on your user data structure
            status: "error",
            duration: 3000,
            isClosable: true,
          });}
        onClose();
    onClose();
  };
  return (
    <>
      <Button bg={"rgba(102, 45, 145, 1)"} style={btnStyle} onClick={onOpen}>
        Verify
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent bg={"transparent"} boxShadow={"none"}>
          <ModalBody p={"30px"}>
            <Flex flexDir={"column"} gap={15}>
              <Flex gap={5}>
                <Button bg={status==="APPROVED"?"#fff":"transparent"} color={status==="APPROVED"?"black":"white"} onClick={()=>{setStatus("APPROVED")}}>
                  Accept
                </Button>
                <Button bg={status==="REJECTED"?"#e35f5f":"transparent"} color={"white"} onClick={()=>{setStatus("REJECTED")}}>
                  Reject
                </Button>
              </Flex>
              <Flex flexDir="column" gap={"15px"} bg={"#ffffff"} p={4} borderRadius={4}>
                  <Box>
                    <Text style={textStyle} fontWeight={600}>Category</Text>
                    <Text style={textStyle}>{report.category}</Text>
                  </Box>
                  <Box>
                    <Text style={textStyle} fontWeight={600}>Comment</Text>
                    <Text style={textStyle}>{report.reason}</Text>
                  </Box>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <FormControl>
                  <FormLabel>Remark</FormLabel>
                  <Textarea
                    type="text"
                    name="remark"
                    autoComplete="off"
                    placeholder="Remark"
                    {...register("remark", { required: "Remark is required" })}
                    />
                  {errors.remark && <p>{errors.remark.message}</p>}
                  </FormControl>

                  <Button
                    mt={"20px"}
                    type="submit"
                    style={btnStyle}
                    bg={"#662D91"}
                  >
                    Submit
                  </Button>
                </form>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ReportHandler;