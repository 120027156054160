import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Flex, HStack, Image, Input, Text, Textarea, VStack, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCourseToWishlist, fetchCourse, removeCourseFromWishlist, reviewCourse } from "../../store/user/userAction";
import ReviewCard from "../../components/course/ReviewCard";
import CreateReport from "../../components/course/CreateReport";
import LoadingPage from "./../LoadingPage"
import moment from 'moment'
import addToWishlist from './../../images/wishlist.png'
import removeFromWishlist from './../../images/wishlisted.png'
import { updateCourse } from "../../store/creator/creatorAction";
import { useForm } from "react-hook-form";
import UpdateCourse from "../creator/UpdateCourse";
import AdminRequestHandler from "../admin/RequestHandler";
import ReactStars from "react-rating-stars-component";
import HtmlDataParse from "./htmlDataParse";
const CourseDescription = () => {
    const [rating, setRating] = useState(0);
    const ratingChanged = (rate) => {
        setRating(rate);
    }
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const btnStyle = {
        fontSize: "40px",
        padding: "4px",
        fontWeight: "Bold",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
    }
    const submitBtnStyle = {
        fontFamily: '"Outfit", sans-serif',
        fontWeight: 400,
        fontSize: "17px",
        padding: "5px 25px",
        borderRadius: "20px",
        color: "#FFFFFF",
        backgroundColor: "#662D91",
    }
    const bulletStyle = {
        borderRadius: "5px",
        border: "0.5px solid #474545",
        background: "#FFF",
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
        width: "14px",
        height: "14px"
    }
    const tagStyle = {
        borderRadius: "5px",
        border: "0.5px solid #F5EDED",
        background: "#F5EDED",
        color: "#000",
        fontSize: "15px",
        padding: "5px 10px",
        fontWeight: 400,
        fontFamily: '"Inter",sans-serif'
    }
    const { courseId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCourse(courseId));
    }, [dispatch, courseId]);

    const course = useSelector((state) => state.course.course);
    const loading = useSelector((state) => state.course.loading);
    const user = useSelector((state) => state.user.user);
    const creator = useSelector((state) => state.creator.creator);
    const admin = useSelector((state) => state.admin.admin);
    const toast = useToast();
    const onSubmit = async (data) => {
        data["id"] = course._id;
        data["rating"] = rating;
        const result = await dispatch(reviewCourse(data));
        if (result.payload.success) {
            toast({
                description: `Reviewed Successfully!`, // Adjust based on your user data structure
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
        if (result.payload.errorMessage) {
            toast({
                description: result.payload.errorMessage, // Adjust based on your user data structure
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }
    const addWishlistHandler = async (data) => {
        const result = await dispatch(addCourseToWishlist(data));
        if (result.payload.success) {
            toast({
                title: `Success`,
                description: `Added to wishlist`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
        if (result.payload.errorMessage) {
            toast({
                description: result.payload.errorMessage,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }
    const removeWishlistHandler = async (data) => {
        const result = await dispatch(removeCourseFromWishlist(data));
        if (result.payload.success) {
            toast({
                title: `Success`,
                description: `Removed from wishlist`,
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        }
        if (result.payload.errorMessage) {
            toast({
                description: result.payload.errorMessage,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }
    const handleCourseStatus = async (data) => {
        data._id = courseId;
        const result = await dispatch(updateCourse(data));
        if (result.payload.success) {
            toast({
                description: data.status === "PENDING" ? `"${course.title}" submitted for approval` : `"${course.title}" deleted`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate("/creator/courses")
        }
        if (result.payload.errorMessage) {
            toast({
                description: result.payload.errorMessage,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };
    let isFavCourse = false;
    if (user) {
        if (user.wishlist[0]?._id) {
            for (let i = 0; i < user.wishlist.length; i++) {
                if (user.wishlist[i]._id.toString() === courseId.toString()) {
                    isFavCourse = true;
                    break;
                }
            }
        } else {
            if (user.wishlist.includes(courseId.toString())) {
                isFavCourse = true;
            }
        }
    }
    let isReviewed = null;
    if (user && course?.reviews) {
        for (let i = 0; i < course.reviews.length; i++) {
            if (course.reviews[i].user._id.toString() === user._id.toString()) {
                isReviewed = course.reviews[i];
                break;
            }
        }
    }
    return (
        loading ? <LoadingPage /> :
            course &&
            <>
                <Flex wrap={"wrap"} px={"5vw"} gap={10} py={"50px"} bg={"#2D2F31"} align={"flex-start"} justify={"center"} color={"#FFFFFF"} fontFamily={"'Outfit',sans-serif"}>
                    <Flex flex={1} flexDir={"column"} align={"flex-start"} w={"96vw"} m={"auto"} minW={"350px"} gap={5}>
                        <Image borderRadius={2} boxSize={"110px"} src={course.creator?.image?.url} alt={`${course.creator?.firstName} ${course.creator?.lastName}`} />
                        <Box>
                            <Text fontWeight={600} fontSize={"3xl"}>{course.title}</Text>
                            {creator && course.status === "REJECTED" && <Text color={"red.400"}>(REJECTED)</Text>}
                        </Box>
                        <Flex wrap={"wrap"} gap={2} mt={"15px"}>
                            {course.certificate && <Text style={tagStyle}>Certification: {course.certificate}</Text>}
                            <Text style={tagStyle}>Start Date: {moment(course.startDate).format('DD-MM-YYYY')}</Text>
                            {course.learningMode && <Text style={tagStyle}>Learning Mode: {course.learningMode}</Text>}
                            {course.deliveryMode && <Text style={tagStyle}>Delivery Mode: {course.deliveryMode}</Text>}
                            {course.financeAvailability && <Text style={tagStyle}>EMI : {course.financeAvailability}</Text>}
                        </Flex>
                        {user && <Flex gap={3} fontSize={"2xl"}>
                            <Flex align={"center"} justify={"center"} gap={2} cursor={"pointer"} onClick={() => { isFavCourse ? removeWishlistHandler(courseId) : addWishlistHandler(courseId) }}><Image src={isFavCourse ? removeFromWishlist : addToWishlist} alt={isFavCourse?"Remove from Wishlist":"Add to Wishlist"} boxSize={"30px"} /><Text fontSize={"md"}>{isFavCourse?"Remove from Wishlist":"Add to Wishlist"}</Text></Flex>
                        </Flex>}
                    </Flex>
                    <Flex w={"90vw"} maxW={"400px"} minW={"320px"} flexDir={"column"} bg={"#FFFFFF"} color={"#000000"} py={5} px={4} borderRadius={10} gap={7}>
                        <HStack w={"100%"} justify={"space-between"} align={"flex-start"} fontSize={"md"} fontFamily={"Inter, sans-serif"}>
                            <VStack align={"flex-start"} gap={"0px"}>
                                <Text as={"b"}>{course.programType}</Text>
                                <Text fontSize={"xs"}>Created by {course.creator.firstName} {course.creator.lastName}</Text>
                            </VStack>
                            <Flex align={"center"} gap={2}>
                                <Text as={"b"}>{course.rating}⭐</Text>
                                <Text fontSize={"sm"} color={"gray.600"}> {`(${course.reviews.length} ${course.reviews.length > 1 ? "Reviews" : "Review"})`}</Text>
                            </Flex>
                        </HStack>
                        <VStack align={"flex-start"}>
                            {course.courseLevel && <HStack><Box style={bulletStyle} /><Text>{course.courseLevel} Level</Text></HStack>}
                            {course.language && <HStack><Box style={bulletStyle} /><Text>{course.language}</Text></HStack>}
                            {course.duration && <HStack><Box style={bulletStyle} /><Text>{course.duration}</Text></HStack>}
                            {course.courseType && <HStack><Box style={bulletStyle} /><Text>{course.courseType}</Text></HStack>}
                            <HStack><Box style={bulletStyle} /><Text>{course.price === 0 ? "Free" : `₹ ${course.price}`}</Text></HStack>
                        </VStack>
                        {(user || creator || admin) ? <a href={course.url} target="_blank"><Button style={submitBtnStyle} w={"90%"} m={"auto"}>Go to Course</Button></a> : <Button style={submitBtnStyle} w={"90%"} m={"auto"} onClick={() => {
                            toast({
                                description: `Login to access course`, // Adjust based on your user data structure
                                status: "error",
                                duration: 3000,
                                isClosable: true,
                            });
                        }}>Go to Course</Button>}
                    </Flex>
                </Flex>
                <Flex p={"10px 5vw"} maxW={"94dvw"} m={"auto"} flexDir={"column"} align={"center"} alignItems={"flex-start"} gap={10}>
                    <Flex gap={2}>
                        {creator && creator._id.toString() === course.creator._id.toString() && <>
                            {course.status === "DRAFT" && <Button style={submitBtnStyle} onClick={() => { handleCourseStatus({ status: "PENDING" }) }}>Publish</Button>}
                            {course.status !== "REJECTED" && <Button style={submitBtnStyle} onClick={() => { handleCourseStatus({ status: "REJECTED" }) }}>Delete</Button>}
                            <UpdateCourse btnStyle={submitBtnStyle} course={course} />
                        </>
                        }
                        {admin && <>
                            <AdminRequestHandler btnStyle={submitBtnStyle} courseId={courseId} />
                        </>
                        }
                    </Flex>
                    {(creator || admin) && course.remark && <Box>
                        <Text className="courseDescHeading">Remark</Text>
                        <Text className="courseDescText">{course.remark}</Text>
                    </Box>}
                    {course?.description && <Box w={"100%"}>
                        <Text className="courseDescHeading">Course Overview</Text>
                        <HtmlDataParse htmlContent={course.description} />
                    </Box>}
                    {course?.preRequisite && <Box>
                        <Text className="courseDescHeading">Pre requisites </Text>
                        <HtmlDataParse htmlContent={course.preRequisite} />
                    </Box>}
                    {course?.outcome && <Box>
                        <Text className="courseDescHeading">Course Outcome </Text>
                        <HtmlDataParse htmlContent={course.outcome} />
                    </Box>}
                    {course?.workExperience && <Box>
                        <Text className="courseDescHeading">Work Experience</Text>
                        <Text className="courseDescText">{course.workExperience}</Text>
                    </Box>}
                    {course?.placementAssistance && <Box>
                        <Text className="courseDescHeading">Placement Assistance</Text>
                        <Text className="courseDescText">{course.placementAssistance}</Text>
                    </Box>}
                    {course?.subtitles && <Box>
                        <Text className="courseDescHeading">Subtitles Availability</Text>
                        <Text className="courseDescText">{course.subtitles}</Text>
                    </Box>}
                    {user && <CreateReport btnStyle={btnStyle} submitBtnStyle={submitBtnStyle} courseId={courseId} />}

                    <Divider borderColor={"black"} />
                    <Box w={"100%"}>
                        <Text className="courseDescHeading">Reviews</Text>
                        {user && <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            {/* <Input type="number" name="rating" autoComplete="off" placeholder="Rating" {...register("rating", { required: "Rating is required" })} />
                            {errors.rating && <p>{errors.rating.message}</p>} */}
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={32}
                                activeColor="#ffd700"
                            />

                            <Textarea pr="4.5rem" type="text" name="comment" placeholder="Comment" autoComplete="off" mt={"15px"} {...register("comment", { required: "Comment is required", })} />
                            {errors.comment && <p>{errors.comment.message}</p>}

                            <Button mt={3} type="submit" alignSelf={"flex-end"} style={submitBtnStyle}>Submit</Button>
                        </form>}
                        <Flex width={"94%"} flexDir={"column"} gap={"35px"}>
                            {course.reviews.length === 0 ? <Text>No Reviews</Text> : course.reviews.map((review) => { return <ReviewCard review={review} /> })}
                        </Flex>
                    </Box>
                </Flex>
            </>
    );
};

export default CourseDescription;
