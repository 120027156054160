import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, useToast, } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import logo from "./../../images/Wezlaa logo.png";
import { adminClearError } from "../../store/admin/admin";
import { adminGoogleLogin, adminLogin, adminRegister } from "../../store/admin/adminAction";
import ForgetPassword from "./ForgetPassword";
import { GoogleLogin } from "@react-oauth/google";
const AdminAuth = ({ close }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const submitHandler = async (data) => {
    const result = await dispatch(signup ? adminRegister(data) : adminLogin(data));
    if (result.payload.admin) {
      toast({
        description: `Welcome ${result.payload.admin.firstName} ${result.payload.admin.lastName}!`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      close();
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(adminClearError());
    }
  };
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [signup, setSignup] = useState(false);
  const googleLoginSuccess = async(credentialResponse) => {
    const result = await dispatch(adminGoogleLogin({token:credentialResponse.credential}));
    if (result.payload.admin) {
      toast({
        description: `Welcome ${result.payload.admin.firstName} ${result.payload.admin.lastName}!`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      close();
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(adminClearError());
    }
  }
  return (
    <>
      <Text className="menuText" onClick={onOpen}>
        Admin
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size={signup?"4xl":"md"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent backgroundColor={"transparent"} boxShadow={"none"}>
          <ModalBody>
            <Flex flexDir="column" align={"center"} gap={"15px"}>
              <Flex
                backgroundColor={"white"}
                boxShadow={"none"}
                padding={"25px 15px"}
                borderRadius={"15px"}
                flexDir={"column"}
                gap={5}
                align={"center"}
                w={"100%"}
              >
                <Image src={logo} alt="WEZLAA" h={"44px"} m={"20px 0px"}/>
                <form onSubmit={handleSubmit(submitHandler)} autoComplete="off" style={{width:"95%", display:"flex", flexDirection:"column", gap:10}}>
                  <Input
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder="Email"
                    {...register("email", { required: "Email is required" })}
                    />
                  {errors.email && <p className="formError">{errors.email.message}</p>}

                  {signup && <Input
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    placeholder="FirstName"
                    {...register("firstName", { required: "FirstName is required" })}
                    />}
                  {signup &&  errors.firstName && <p className="formError">{errors.firstName.message}</p>}

                  {signup && <Input
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    placeholder="LastName"
                    {...register("lastName", { required: "LastName is required" })}
                    />}
                  {signup &&  errors.lastName && <p className="formError">{errors.lastName.message}</p>}
                  

                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      autoComplete="off"
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && <p className="formError">{errors.password.message}</p>}

                  {signup && <Input
                    type="number"
                    name="contactNumber"
                    autoComplete="off"
                    placeholder="Contact Number"
                    {...register("contactNumber", { required: "Contact Number is required" })}
                    />}
                  {signup && errors.contactNumber && <Text className="formError">{errors.contactNumber.message}</Text>}

                  <Button
                    type="submit"
                    colorScheme="blue"
                    w={"100%"}
                    mt={3}
                    borderRadius={"8px"}
                    bg={"#662D91"}
                    fontFamily={"Outfit"}
                    fontWeight={400}
                  >
                    {signup?"Register":"Login"}
                  </Button>
                </form>
                <ForgetPassword profile={"admin"} />
                <GoogleLogin onSuccess={(credentialResponse)=>googleLoginSuccess(credentialResponse)} onError={() => {
            console.log("Login Failed");
          }}/>
              </Flex>
              <Box
                backgroundColor={"white"}
                boxShadow={"none"}
                padding={"15px"}
                borderRadius={"15px"}
                w={"100%"}
                textAlign={"center"}
                
              >
                {!signup && <Text fontFamily={"Outfit"}>Don't have account? <span style={{cursor:"pointer",color:"#662D91"}} onClick={()=>{setSignup(!signup)}}>Sign Up</span></Text>}
                {signup && <Text fontFamily={"Outfit"}>Already have account? <span style={{cursor:"pointer",color:"#662D91"}} onClick={()=>{setSignup(!signup)}}>Login</span></Text>}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminAuth;
