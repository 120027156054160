import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateCourseStatus } from "../../store/admin/adminAction";
import { useNavigate } from "react-router-dom";

const AdminRequestHandler = ({ btnStyle, courseId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [publish,setPublish] = useState("PUBLIC");
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const onSubmit = async(data) => {
    data["courseId"] = courseId;
    data["status"] = publish;
    const result = await dispatch(updateCourseStatus(data));;
        if(result.payload.success){toast({
            description: "Course status Updated", // Adjust based on your user data structure
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          navigate("/admin/courses")
        }
        if(result.payload.errorMessage){toast({
            description: result.payload.errorMessage, // Adjust based on your user data structure
            status: "error",
            duration: 3000,
            isClosable: true,
          });}
        onClose();
  };
  return (
    <>
      <Button bg={"rgba(102, 45, 145, 1)"} style={btnStyle} onClick={onOpen}>
        Update Status
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent bg={"transparent"} boxShadow={"none"}>
          <ModalBody p={"30px"}>
            <Flex flexDir={"column"} gap={15}>
              <Flex gap={5}>
                <Button bg={publish==="PUBLIC"?"#fff":"transparent"} color={publish==="PUBLIC"?"black":"white"} onClick={()=>{setPublish("PUBLIC")}}>
                  Accept
                </Button>
                <Button bg={publish==="REJECTED"?"#fff":"transparent"} color={publish==="REJECTED"?"black":"white"} onClick={()=>{setPublish("REJECTED")}}>
                  Reject
                </Button>
              </Flex>
              <Flex flexDir="column" gap={"15px"} bg={"#ffffff"} p={4} borderRadius={4}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <FormControl>
                  <FormLabel>Remark</FormLabel>
                  <Input
                    type="text"
                    name="remark"
                    autoComplete="off"
                    placeholder="Remark"
                    {...register("remark", { required: "Remark is required" })}
                    />
                  {errors.remark && <p>{errors.remark.message}</p>}
                  </FormControl>

                  <Button
                    mt={"20px"}
                    type="submit"
                    style={btnStyle}
                    bg={"#662D91"}
                  >
                    Submit
                  </Button>
                </form>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default AdminRequestHandler;
