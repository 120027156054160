import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationConfig, axiosInstance } from "../configActions";

export const adminLogin = createAsyncThunk(
  "adminLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `/loginAdmin`,
        data,
        applicationConfig
      );
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const adminRegister = createAsyncThunk(
  "adminRegister",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `/registerAdmin`,
        data,
        applicationConfig
      );
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const adminLogout = createAsyncThunk(
  "adminLogout",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.put(`/logoutAdmin`);
      return result;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const adminGoogleLogin = createAsyncThunk(
  "adminGoogleLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/admin/google/auth`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const getAdmin = createAsyncThunk(
  "getAdminDetails",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/admin`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// update admin
export const updateAdmin = createAsyncThunk(
  "updateAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `/admin`,
        data,
        applicationConfig
      );
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// Update course status
export const updateCourseStatus = createAsyncThunk(
  "updateCourseStatus",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await axiosInstance.put(`/adminCourse/${data.courseId}`,data);
      dispatch(fetchAdminCourses({status:"PENDING"}))
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch courses
export const fetchAdminCourses = createAsyncThunk(
  "fetchAdminCourses",
  async (filter, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/adminCourses`,{params:filter});
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch categories
export const fetchCategories = createAsyncThunk(
  "fetchCategories",
  async (args, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/categories`,applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// Add category
export const addCategory = createAsyncThunk(
  "addCategory",
  async (data, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.post(`/addCategory`,data,applicationConfig);
      await dispatch(fetchCategories());
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// Add sub-category
export const addSubCategory = createAsyncThunk(
  "addSubCategory",
  async (data, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.post(`/addSubCategory`,data,applicationConfig);
      await dispatch(fetchCategories());
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch reports
export const fetchAdminReports = createAsyncThunk(
  "fetchAdminReports",
  async (filters, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/reports`,{params:filters});
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// handle report
export const reportHandler = createAsyncThunk(
  "reportHandler",
  async (data, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.put(`/report/${data.reportId}`,data);
      dispatch(fetchAdminReports({status:"PENDING"}));
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);