import { createSlice } from '@reduxjs/toolkit';
import { searchCourses } from '../user/userAction';

const initialState = {
  loading: false,
  error: null,
  courses: null,
};

const searchCoursesSlice = createSlice({
  name: 'searchCourses',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.courses = action.payload.courses ? action.payload.courses : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(searchCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchCourses.fulfilled, handleAsyncAction)
      .addCase(searchCourses.rejected, handleAsyncAction)
  },
});

export const { clearError } = searchCoursesSlice.actions;
export default searchCoursesSlice.reducer;