import React from 'react'
import {Heading, Text, VStack} from '@chakra-ui/react'
const EmptyPage = ({message}) => {
  
    return (
        <VStack minH={"90vh"} align={"center"} justify={"center"}>
          <Heading>Not Available</Heading>
          <Text>{message}!</Text>
        </VStack>
    );
}

export default EmptyPage