import React, { useState } from 'react'
import { Avatar, Flex, Image, Text } from '@chakra-ui/react'
import starImage from "./../../images/course card rating star.svg"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
const CourseCard = ({ course }) => {
  const { creator } = useSelector((state) => state.creator)
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const truncateTitle = (text, length) => {
    if (text.length <= length) {
      return text;
    } else {
      return text.substring(0, length) + '...';
    }
  };
  return (
    <Link to={`/course/${course._id}`}>
      <Flex flexDir={"column"} alignItems={"flex-start"} maxW={"95vw"} w={"365px"} overflow={"hidden"} style={{ borderRadius: "20px", background: "#FFF", boxShadow: "5px 4px 4px 0px rgba(0, 0, 0, 0.25)" }} transition={"transform 0.3s"} _hover={{ transform: "scale(1.04)" }} onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Image src={course?.banner?.url} alt={course.title} w={"100%"} h={"200px"} />
        <Flex alignItems={"flex-start"} gap={3} p={"8px"}>
          <Avatar src={course.creator.image?.url} />
          <Flex flexDir={"column"} gap={"4px"}>
            <Flex flexDir={"column"}>
              <Text flex={"1 0 0"} className='courseCardFont'>{isHovered ? course.title : truncateTitle(course.title, 32)}</Text>
              <Text color={"#7D7979"} className='courseCardFont' >{course.creator.firstName} {course.creator.lastName}</Text>
            </Flex>
            <Flex gap={"4px"} align={"center"} alignSelf={"stretch"} wrap={"wrap"}>
              <Text className='courseCardFont courseCardPrice'>{course.price === 0 ? "Free" : `₹ ${course.price}`}</Text>
              <Text className='courseCardFont courseCardPrice'>.</Text>
              {course.rating ? <>
                <Image src={starImage} alt='⭐' h={"18px"} w={"20px"} />
                <Text className='courseCardFont courseCardPrice'>{course.rating} / 5</Text></> : <Text className='courseCardFont' color={"gray.500"}>No Reviews</Text>}
              {creator && course.status === "REJECTED" && <Text className='courseCardFont courseCardPrice' color={"red.400"}> REJECTED</Text>}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}

export default CourseCard