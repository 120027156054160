import React from "react";
import { Flex, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import CourseCard from "./CourseCard";
import { searchCourses } from "../../store/user/userAction";
import { FiSearch } from "react-icons/fi";
const SearchBar = ({ close }) => {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const {courses} = useSelector((state)=>state.searchCourses);
  const handleChange = (e) =>{
    dispatch(searchCourses({title:e.target.value}))
  }
  return (
    <>
      {
      isSmallerThan768?<FiSearch  fontSize={"xl"} color="gray.600" fontWeight={600} onClick={onOpen}/>:
      <Flex onClick={onOpen} _hover={{border:"3px solid #662D91"}} border={"3px solid transparent"} cursor={"pointer"} align={"center"} gap={2} p={"5px 20px"} borderRadius={"10px"} maxW={"35vw"} flex={1} boxShadow={"0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"}>
      <FiSearch  fontSize={"xl"} color="gray.600" fontWeight={600}/>
      <Text fontFamily={'"Inter", sans-serif'} color={"gray.600"} fontSize={"lg"} fontWeight={400}>
        Search
      </Text>
      </Flex>}
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent backgroundColor={"transparent"} boxShadow={"none"}>
          <ModalBody>
            <Flex flexDir="column" align={"center"} gap={"15px"}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <FiSearch color='gray.300' />
                </InputLeftElement>
                <Input type="text" backgroundColor={"white"} placeholder="Course title" onChange={handleChange}/>
              </InputGroup>
              <Flex
                flexDir={"column"}
                align={"center"}
                backgroundColor={"white"}
                borderRadius={"15px"}
                w={"100%"}
              >
                {courses?.map((course)=>{return <CourseCard course={course} close={onClose}/>})}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchBar;