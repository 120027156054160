import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getWishlist } from '../../store/user/userAction';
import CourseCard from '../../components/course/CourseCard';
import { Flex } from '@chakra-ui/react';
import LoadingPage from '../LoadingPage';

const Wishlist = () => {
    const {loading, user} = useSelector((state)=>state.user);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getWishlist());
    }, [dispatch]);
  return (
    <Flex justify={"center"} gap={4} wrap={"wrap"} minH={"90vh"}>
    {loading && <LoadingPage/>}
    {user?.wishlist[0]?.title && user.wishlist.map((element)=>{
      return <CourseCard course={element}/>
    })}
    </Flex>
  )
}

export default Wishlist