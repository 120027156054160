import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import { creatorCourses } from '../../store/creator/creatorAction';
import LoadingPage from '../LoadingPage';
import EmptyPage from '../EmptyPage';
import CourseCard from '../../components/course/CourseCard';

const CreatorReports = () => {
    const {loading,courses} = useSelector((state)=>state.courses);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(creatorCourses({status:"REJECTED"}));
    }, [dispatch]);
  return (
    loading ? <LoadingPage/>:
    <Flex flexDir={"column"} justify={"center"} align={"center"} gap={15} minH={"90vh"}>
        <Text fontSize={"2xl"} fontWeight={600} fontFamily={"Outfit,sans-serif"} my={3}>Rejected Courses</Text>
        <Flex wrap={"wrap"} justify={"center"} gap={5} minH={"90vh"} mb={10}>
            {loading ? <LoadingPage/>: (courses?.length === 0)? <EmptyPage message="No rejected courses"/> : courses?.map((course)=>{return <CourseCard key={course._id} course={course}/>})}
        </Flex>
    </Flex>
  )
}

export default CreatorReports