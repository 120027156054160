import React, { useEffect, useState } from "react";
import { Button, Flex, Input, Text, FormLabel, FormControl, Heading, Avatar, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../components/text editor/TextEditor";
import LoadingPage from "../LoadingPage";
import { getCreator, updateCreator } from "../../store/creator/creatorAction";
import { creatorClearError } from "../../store/creator/creator";

const CreatorProfile = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { loading, creator } = useSelector((state) => state.creator);
  const [updatedCreator, setUpdatedCreator] = useState({ ...creator });

  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUpdatedCreator((prevCreator) => ({ ...prevCreator, ["profileImage"]: reader.result }));
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCreator((prevCreator) => ({ ...prevCreator, [name]: value }));
  };

  const handleAbout = (value) => {
    setUpdatedCreator((prevCreator) => ({ ...prevCreator, ["about"]: value }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const result = await dispatch(updateCreator(updatedCreator));
    if (result.payload.creator) {
      toast({
        description: `Updated Successfully`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(creatorClearError());
    }
  };

  const creatorProfileStyle = {
    fontFamily: '"Outfit",sans-serif',
  }
  const formLabelStyle = {
    fontWeight: 600,
    margin: "0px"
  }
  useEffect(() => {
    dispatch(getCreator());
  }, [])
  useEffect(() => {
    setUpdatedCreator({ ...creator });
  }, [creator])
  return (
    loading ? <LoadingPage /> : creator && (
      <>
        <Flex align={"center"} p={"30px 5vw"} gap={5} wrap={"wrap"} bg={"linear-gradient(90deg, #FAFCFF 0%, rgba(102, 45, 145, 0.64) 62.29%, #662D91 100%)"} w={"100%"}>
          <Avatar src={creator.image?.url} boxSize={"150px"} />
          <Flex flexDir={"column"} color={"#662D91"}>
            <Heading as={"h2"} size={"lg"}>{creator.firstName} {creator.lastName}</Heading>
            <Text size={"lg"}>{creator.profession}</Text>
          </Flex>
        </Flex>
        <Flex flexDir={"column"} align={"center"} p={"30px 5vw"} gap={5}>
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <FormControl style={creatorProfileStyle}>
              <FormLabel style={formLabelStyle}>Email</FormLabel>
              <Input
                disabled={true}
                type="email"
                value={updatedCreator.email}
              />
            </FormControl>
            <FormControl style={creatorProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>First Name</FormLabel>
              <Input
                type="text"
                name="firstName"
                value={updatedCreator.firstName}
                onChange={handleChange}
                placeholder="Enter your First Name"
              />
            </FormControl>
            <FormControl style={creatorProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>Last Name</FormLabel>
              <Input
                type="text"
                name="lastName"
                value={updatedCreator.lastName}
                onChange={handleChange}
                placeholder="Enter your Last Name"
              />
            </FormControl>
            <FormControl style={creatorProfileStyle} isRequired>
              <FormLabel style={formLabelStyle}>Contact Number</FormLabel>
              <Input
                type="number"
                name="contactNumber"
                value={updatedCreator.contactNumber}
                onChange={handleChange}
                placeholder="Enter your contact Number"
              />
            </FormControl>
            <FormControl style={creatorProfileStyle}>
              <FormLabel style={formLabelStyle}>Profile Image</FormLabel>
              <input type="file" accept="image/*" onChange={handleImageUpload} />
            </FormControl>
            <FormControl style={creatorProfileStyle}>
              <FormLabel style={formLabelStyle}>About</FormLabel>
              <TextEditor
                value={updatedCreator.about}
                onChange={handleAbout}
                placeholder={"Describe yourself"}
              />
            </FormControl>
            <FormControl style={creatorProfileStyle}>
              <FormLabel style={formLabelStyle}>Profession</FormLabel>
              <Input
                type="text"
                name="profession"
                value={updatedCreator.profession}
                onChange={handleChange}
                placeholder="Enter your profession"
              />
              <FormLabel style={formLabelStyle}>Website Link</FormLabel>
              <Input
                type="text"
                name="webUrl"
                value={updatedCreator.webUrl}
                onChange={handleChange}
                placeholder="Enter your website link"
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              mt={3}
              borderRadius={"8px"}
              bg={"#662D91"}
              fontFamily={"Outfit"}
              fontWeight={400}
              w={"100px"}
              disabled={loading}
            >
              {!loading ? "Update" : "Updating"}
            </Button>
          </form>
        </Flex>
      </>
    )
  );
};

export default CreatorProfile;