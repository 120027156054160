import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationConfig, axiosInstance } from "../configActions";

export const userLogin = createAsyncThunk(
  "userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/loginUser`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const userGoogleLogin = createAsyncThunk(
  "userGoogleLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/user/google/auth`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const forgotPassword  = createAsyncThunk(
  "forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/${data.profile}/password/forgot`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const resetPassword  = createAsyncThunk(
  "resetPassword",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const result = await axiosInstance.put(`/${data.profile}/password/reset/${data.token}`, data, applicationConfig);
      await dispatch(getUser());
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const userRegister = createAsyncThunk(
  "userRegister",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/registerUser`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const userLogout = createAsyncThunk(
  "userLogout",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.put(`/logoutUser`);
      return result;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const getUser = createAsyncThunk(
  "getUserDetails",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/user`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);
  
// user wishlist
export const getWishlist = createAsyncThunk(
  "getUserWishlist",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/user/wishlist`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch courses
export const fetchCourses = createAsyncThunk(
  "fetchCourses",
  async (filters, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/courses`,{params:filters});
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch courses
export const fetchRandomCourses = createAsyncThunk(
  "fetchRandomCourses",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/randomCourses`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// search courses
export const searchCourses = createAsyncThunk(
  "searchCourses",
  async (filters, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/courses`,{params:filters});
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch course details
export const fetchCourse = createAsyncThunk(
  "fetchCourses",
  async (id, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/course/${id}`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// Add course to wishlist
export const addCourseToWishlist = createAsyncThunk(
  "addCourseToWishlist",
  async (id, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.put(`/course/${id}`);
      dispatch(getUser());
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// Remove course from wishlist
export const removeCourseFromWishlist = createAsyncThunk(
  "removeCourseFromWishlist",
  async (id, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.delete(`/course/${id}`);
      dispatch(getUser());
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// review course
export const reviewCourse = createAsyncThunk(
  "reviewCourse",
  async (data, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.post(`/review/${data.id}`,data);
      dispatch(fetchCourse(data.id));
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// update user
export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/user`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// report course
export const reportCourse = createAsyncThunk(
  "reportCourse",
  async (data, { rejectWithValue,dispatch }) => {
    try {
      const result = await axiosInstance.post(`/report/${data.id}`,data);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch creator profile