import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationConfig, axiosInstance } from "../configActions";

export const creatorLogin = createAsyncThunk(
  "creatorLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/loginCreator`, data, applicationConfig);
      console.log("result");
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const creatorRegister = createAsyncThunk(
  "creatorRegister",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/registerCreator`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const creatorLogout = createAsyncThunk(
  "creatorLogout",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.put(`/logoutCreator`);
      return result;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const creatorGoogleLogin = createAsyncThunk(
  "googleLogin",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/creator/google/auth`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

export const getCreator = createAsyncThunk(
  "getCreatorDetails",
  async (arg, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/creator`);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// fetch creator courses
export const creatorCourses = createAsyncThunk(
  "creatorCourses",
  async (filter, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(`/creator/courses`,{params:filter});
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// create course
export const createCourse = createAsyncThunk(
  "createCourse",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/createCourse`,data);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// update 
export const updateCourse = createAsyncThunk(
  "updateCourse",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/updateCourse/${data._id}`,data);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);

// update creator
export const updateCreator = createAsyncThunk(
  "updateCreator",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(`/creator`, data, applicationConfig);
      return result.data;
    } catch (error) {
      return rejectWithValue({
        errorMessage: error.response?.data?.message || error.message,
      });
    }
  }
);