import {
    Flex,
    Box,
    Text
} from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../store/admin/adminAction";
import { useNavigate } from 'react-router-dom'

const Categories = () => {
    const {categories} = useSelector((state)=>state.categories);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categoryHandler = (category) => {
        navigate(`/learn/${category.urlName}`);
        setOpenCategory(null);
        setMenuOpen(false);
    }
    const subCategoryHandler = ({category,subcategory}) => {
        navigate(`/learn/${category.urlName}/${subcategory.urlName}`);
        setOpenCategory(null);
        setMenuOpen(false);
    }
    useEffect(()=>{
        dispatch(fetchCategories());
    },[])
    const [openCategory, setOpenCategory] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Box pos={"relative"} onMouseLeave={() => setMenuOpen(false)}>
            <Text className="navText" cursor={"pointer"} onMouseOver={() => setMenuOpen(true)} onClick={()=>{navigate("/learn/undefined")}}>
                Explore
            </Text>
            {menuOpen && 
                <Flex 
                    pos={"absolute"}
                    zIndex={2}
                    fontFamily={"Outfit, sans-serif"} 
                    fontSize={"md"} 
                    borderRadius={"0px 0px 10px 10px"} 
                    boxShadow={"0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"}
                    background={"#FFF"} 
                    >
                    <Flex 
                        p={2} 
                        background={"#FFF"} 
                        overflow={"auto"} 
                        maxH={"90dvh"}
                        flexDir={"column"} 
                        gap={1}
                        >
                        {categories?.map((e)=>{
                            return  <Flex 
                                        onClick={()=>{categoryHandler(e)}} 
                                        bg={openCategory?._id===e._id?"gray.200":"#FFF"} 
                                        cursor={"pointer"} w={"220px"} 
                                        align={"center"} 
                                        justify={"space-between"} 
                                        _hover={{background:"gray.100"}} 
                                        onMouseOver={()=>{setOpenCategory(e)}} 
                                        px={2} 
                                        py={1} 
                                        borderRadius={"5px"}
                                        >
                                        <Text textTransform={"capitalize"}>{e.name}</Text>
                                        <MdKeyboardArrowRight/>
                                    </Flex>
                            })
                        }
                    </Flex>
                    {openCategory && 
                        <Box 
                            p={2} 
                            background={"#FFF"} 
                            flexDir={"column"} 
                            gap={1} 
                            overflow={"auto"} 
                            maxH={"90dvh"}
                            borderLeft={"0.75px solid gray"}
                        >
                            {openCategory?.subCategories?.map((e)=>{ return <Text onClick={()=>{subCategoryHandler({category:openCategory,subcategory:e})}} w={"220px"} cursor={"pointer"} _hover={{background:"gray.100"}} px={2} py={1} borderRadius={"5px"}>{e.name}</Text>})}
                        </Box>
                    }
                </Flex>
            }
        </Box>
    );
};

export default Categories;