import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import Filters from './Filters';

const ExploreDrawer = ({ setFilters, filters }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const btnRef = React.useRef()
    return (
        <>
            <RxHamburgerMenu ref={btnRef} onClick={onOpen} />
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody>
                        <Filters setFilters={setFilters} filters={filters}/>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
export default ExploreDrawer