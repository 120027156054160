import { createSlice } from '@reduxjs/toolkit';
import { fetchCourses, fetchRandomCourses } from '../user/userAction';
import { creatorCourses } from '../creator/creatorAction';
import { fetchAdminCourses } from '../admin/adminAction';

const initialState = {
  loading: false,
  error: null,
  courses: null,
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.courses = action.payload.courses ? action.payload.courses : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(fetchCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourses.fulfilled, handleAsyncAction)
      .addCase(fetchCourses.rejected, handleAsyncAction)
      .addCase(fetchRandomCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRandomCourses.fulfilled, handleAsyncAction)
      .addCase(fetchRandomCourses.rejected, handleAsyncAction)
      .addCase(creatorCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorCourses.fulfilled, handleAsyncAction)
      .addCase(creatorCourses.rejected, handleAsyncAction)
      .addCase(fetchAdminCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdminCourses.fulfilled, handleAsyncAction)
      .addCase(fetchAdminCourses.rejected, handleAsyncAction)

  },
});

export const { clearError } = coursesSlice.actions;
export default coursesSlice.reducer;