import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Divider,
  Button,
} from "@chakra-ui/react";
import UserAuth from "../auth/User";
import CreatorAuth from "../auth/Creator";
import AdminAuth from "../auth/Admin";

const LoginMenu = ({btnStyle,overlayStyle,contentStyle}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button className="menuText" style={btnStyle} onClick={onOpen}>Join</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={overlayStyle}/>
        <ModalContent style={contentStyle}>
          <ModalBody>
            <UserAuth close={onClose}/>
            <Divider/>
            <CreatorAuth close={onClose}/>
            <Divider/>
            <AdminAuth close={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginMenu;
