import { Box, Divider, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import logo from "./../images/LogoImg.svg"
import creatorImage from "./../images/creator-about.svg"
import ImageRollOverImg from "./../images/Region - Slides.png"

const AboutUs = () => {
  return (
    <Flex flexDir={"column"} gap={50} my={"60px"} fontFamily={'"Inter",sans-serif'} w={"90%"} mx={"auto"}>
      <Flex wrap={"wrap"} align={"flex-start"} justify={"space-between"} w={"100%"} m={"auto"} maxW={"1200px"} gap={"100px"}>
        <Flex align={"flex-start"} flexDir={"column"} wrap={"wrap"} flex={1} gap={4}>
          {/* Text */}
          <Text fontWeight={500}>OUR MISSION</Text>
          <Text fontSize={"5xl"} lineHeight={"110%"} fontWeight={600}>“Centralized Course Aggregation”</Text>
          <Text fontSize={"md"} fontWeight={400} align={"justify"}>At Wezlaa, our mission is clear: to empower students and learners by offering a centralized platform for skill development. Through centralized course aggregation, we streamline the learning process, making it easier than ever for individuals to acquire the knowledge and skills they need to succeed.<br />
            "We gather courses from different places so you don’t have to search all the web."<br />
            In a world inundated with information, finding the right courses can be a daunting task. That's why we've created a platform that brings together courses from various sources, eliminating the need for learners to scour the web in search of relevant content.<br />
            With Wezlaa, learners can access a diverse range of courses—all in one place. From YouTube tutorials to e-learning projects, Instagram content, and offline teaching materials, our platform curates the best educational resources, ensuring that learners have everything they need to succeed at their fingertips.<br />
            Our commitment to centralized course aggregation extends beyond convenience—it's about empowering learners to take control of their education, explore new interests, and unlock their full potential. By providing a centralized platform for skill development, we're breaking down barriers to learning and democratizing education for all.<br />
            Join Wezlaa and embark on a journey of discovery, growth, and lifelong learning. Together, we're building a future where education is accessible, inclusive, and empowering for everyone.</Text>
        </Flex>
        {/* Image */}
        <Image src={logo} alt="WEZLAA" w={"20vw"} />
      </Flex>
      <VStack w={"90%"} m={"auto"} align={"flex-start"} gap={10}>
        <Text fontSize={"5xl"} fontWeight={200}>FEATURES</Text>
        <HStack wrap={"wrap"} justify={"space-between"} align={"flex-start"} gap={5} w={"100%"}>
          <VStack flex={1} align={"flex-start"} gap={3} w={"45%"} minW={"315px"}>
            <Text fontWeight={700} fontSize={"3xl"}>Empowering Learners</Text>
            <Text fontWeight={300}>Wezlaa's mission is to empower students and learners through centralized skill development.</Text>
          </VStack>
          <VStack flex={1} align={"flex-start"} gap={3} w={"45%"} minW={"315px"}>
            <Text fontWeight={700} fontSize={"3xl"}>Streamlined Learning</Text>
            <Text fontWeight={300}>Our platform aggregates courses from various sources, simplifying the learning process.</Text>
          </VStack>
          </HStack>
          <HStack wrap={"wrap"} justify={"space-between"} align={"flex-start"} gap={5} w={"100%"}>
          <VStack flex={1} align={"flex-start"} gap={3} w={"45%"} minW={"315px"}>
            <Text fontWeight={700} fontSize={"3xl"}>Diverse Content</Text>
            <Text fontWeight={300}>Access a diverse range of courses, including YouTube tutorials, Instagram content, and offline teaching materials.</Text>
          </VStack>
          <VStack flex={1} align={"flex-start"} gap={3} w={"45%"} minW={"315px"}>
            <Text fontWeight={700} fontSize={"3xl"}>Convenient Access</Text>
            <Text fontWeight={300}>Users can find all their educational resources in one place, eliminating the need to search across multiple platforms.</Text>
          </VStack>
        </HStack>
      </VStack>
      <Image src={ImageRollOverImg} w={"100vw"}/>
      <VStack w={"90%"} m={"auto"} align={"flex-start"} gap={10}>
        <Box w={"100%"}>
          <Text fontWeight={600} fontSize={"3xl"}>For Creators</Text>
          <Divider h={"10px"} borderBottom={"2px solid #000"} />
        </Box>
        <Flex wrap={"wrap"} align={"flex-start"} justify={"space-between"} w={"100%"} m={"auto"} maxW={"800px"} gap={"100px"}>
          {/* Image */}
          <Image src={creatorImage} alt="WEZLAA" h={"50vh"} />
          <Flex align={"flex-start"} flexDir={"column"} wrap={"wrap"} flex={1} gap={4}>
            {/* Text */}
            <Text fontSize={"5xl"} lineHeight={"110%"} fontWeight={600} align={"center"}>“Save Your Marketing Cost”</Text>
            <Text fontSize={"md"} fontWeight={400} align={"justify"}>At Wezlaa, we understand the challenges creators face in promoting their courses cost-effectively. Our platform offers robust analytics tools, empowering creators to understand their audience and course performance without expensive marketing campaigns.<br />
              With Wezlaa, creators can leverage advanced promotional features, including targeted...</Text>
          </Flex>
        </Flex>
      </VStack>
      <VStack w={"90%"} m={"auto"} align={"flex-start"} gap={10}>
        <Box w={"100%"}>
          <Text fontWeight={600} fontSize={"3xl"}>Future Goals</Text>
          <Divider h={"10px"} borderBottom={"2px solid #000"} />
        </Box>
        <Flex wrap={"wrap"} align={"center"} justify={"space-between"} w={"100%"} m={"auto"} maxW={"800px"} gap={"100px"}>
          <Flex align={"flex-start"} flexDir={"column"} wrap={"wrap"} flex={1} gap={4}>
            {/* Text */}
            <Text fontSize={"5xl"} lineHeight={"110%"} fontWeight={600} align={"center"}>Community Reach !!</Text>
          </Flex>
          {/* Image */}
          <Image src={creatorImage} alt="WEZLAA" h={"50vh"} />
        </Flex>
      </VStack>
    </Flex>
  );
};

export default AboutUs;
