import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCourses } from '../../store/user/userAction';
import CourseCard from '../../components/course/CourseCard';
import ExploreDrawer from './Drawer';
import LoadingPage from '../LoadingPage';
import Filters from './Filters';

const Explore = () => {
  const dispatch = useDispatch();
  const [smallerScreen] = useMediaQuery('(max-width: 1070px)');

  const { categories } = useSelector(state => state.categories);
  const { loading, courses } = useSelector(state => state.courses);

  const { categoryUrl, subCategoryUrl } = useParams();

  const selectedCategory = categories?.find(category => category.urlName === categoryUrl);

  const selectedSubcategory =
    selectedCategory &&
    selectedCategory.subCategories.find(subCategory => subCategory.urlName === subCategoryUrl);

  const [filters, setFilters] = useState({ price: [0, 50000] });

  useEffect(() => {
    const temp = { category: selectedCategory?.name, subCategory: selectedSubcategory?.name };
    setFilters(temp);
  }, [selectedCategory, selectedSubcategory, categoryUrl, subCategoryUrl]);

  useEffect(() => {
      dispatch(fetchCourses(filters));
  }, [dispatch, filters]);

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <Flex minH={"90vh"} m={2} gap={4} my={5}>
      {!smallerScreen && <Filters setFilters={setFilters} filters={filters} />}
      <Box flex={1}>
        <Flex align={"center"} w={"95%"} mx={"auto"} gap={5} fontSize={"xl"} fontFamily={"Outfit, sans-serif"} fontWeight={600}>
          {smallerScreen && <ExploreDrawer setFilters={setFilters} filters={filters} />}
          <Text>{selectedCategory?.name} Courses</Text>
        </Flex>
        {!selectedCategory &&
          <Flex justify={"flex-start"} align={"center"} wrap={"wrap"} w={"95%"} mx={"auto"} my={4} gap={3} px={2} py={2} borderRadius={20} bg={"#f9f9f9"}>
            {categories?.slice(0, showAll ? categories.length : 5).map((category) => {
              return (
                <Link to={`/learn/${category.urlName}`}>
                  <Text px={5} py={1} borderRadius={20} fontWeight={600} fontFamily={'"Inter",sans-serif'} color={"#000000"} bg={"gray.100"}>{category.name}</Text>
                </Link>
              )
            })}
            {categories?.length > 5 && (<Text onClick={toggleShowAll} px={5} py={1} borderRadius={20} fontWeight={600} fontFamily={'"Inter",sans-serif'} cursor={"pointer"}>{showAll ? '. . . Show Less' : '. . . Show More'}</Text>)}
          </Flex>
        }
        {selectedCategory &&
          <Flex justify={"flex-start"} align={"center"} wrap={"wrap"} w={"95%"} mx={"auto"} my={4} gap={3} px={2} py={2} borderRadius={20} bg={"#f9f9f9"}>
            {selectedCategory.subCategories.slice(0, showAll ? selectedCategory.subCategories.length : 5).map((category) => {
              return (
                <Link to={`/learn/${selectedCategory.urlName}/${category.urlName}`}>
                  <Text px={5} py={1} borderRadius={20} fontWeight={600} fontFamily={'"Inter",sans-serif'} color={category.urlName === selectedSubcategory?.urlName ? "#ffffff" : "#000000"} bg={category.urlName === selectedSubcategory?.urlName ? "#662D91" : "gray.100"}>{category.name}</Text>
                </Link>
              )
            })}
            {selectedCategory.subCategories.length > 5 && (<Text onClick={toggleShowAll} px={5} py={1} borderRadius={20} fontWeight={600} fontFamily={'"Inter",sans-serif'} cursor={"pointer"}>{showAll ? '. . . Show Less' : '. . . Show More'}</Text>)}
          </Flex>
        }
        {loading ? <LoadingPage /> : courses && <Flex w={"100%"} justify={"center"} m={"auto"} wrap={"wrap"} gap={5}>
          {courses?.length === 0 ? <Text fontFamily={'"Inter",sans-serif'} fontWeight={200} fontSize={"lg"} textAlign={"center"}>No Courses Available</Text> : courses.map((course) => { return (<CourseCard key={course._id} course={course} />) })}
        </Flex>}
      </Box>
    </Flex>
  );
};

export default Explore;
