import { createSlice } from '@reduxjs/toolkit';
import { fetchCourse } from '../user/userAction';
import { createCourse, updateCourse } from '../creator/creatorAction';

const initialState = {
  loading: false,
  error: null,
  course: null,
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    clearCourseError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.course = action.payload.course ? action.payload.course : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(fetchCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourse.fulfilled, handleAsyncAction)
      .addCase(fetchCourse.rejected, handleAsyncAction)
      .addCase(createCourse.pending, (state) => {
        state.loading = true;
        state.course = null;
        state.error = null;
      })
      .addCase(createCourse.fulfilled, handleAsyncAction)
      .addCase(createCourse.rejected, handleAsyncAction)
      .addCase(updateCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourse.fulfilled, handleAsyncAction)
      .addCase(updateCourse.rejected, handleAsyncAction)

  },
});

export const { clearCourseError } = courseSlice.actions;
export default courseSlice.reducer;