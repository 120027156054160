import { configureStore } from "@reduxjs/toolkit";
import userReducer from './user/user'
import courseReducer from './course/course'
import coursesReducer from './course/courses'
import categoriesReducer from './category/category'
import searchCoursesReducer from './course/searchCourses'
import creatorReducer from './creator/creator'
import adminReducer from './admin/admin'
import reportsReducer from './report/reports'

export const store = configureStore({
    reducer:{
        user:userReducer,
        course:courseReducer,
        courses:coursesReducer,
        categories:categoriesReducer,
        searchCourses:searchCoursesReducer,
        creator:creatorReducer,
        admin:adminReducer,
        reports:reportsReducer
    }
})