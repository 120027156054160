import { createSlice } from '@reduxjs/toolkit';
import { creatorGoogleLogin, creatorLogin, creatorLogout, creatorRegister, getCreator, updateCreator } from './creatorAction';

const initialState = {
  loading: false,
  error: null,
  creator: null,
};

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    creatorClearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const handleAsyncAction = (state, action) => {
      state.loading = false;
      state.creator = action.payload.creator ? action.payload.creator : null;
      state.error = action.payload.errorMessage ? action.payload : null;
    };

    builder
      .addCase(creatorLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorLogin.fulfilled, handleAsyncAction)
      .addCase(creatorLogin.rejected, handleAsyncAction)

      .addCase(creatorGoogleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorGoogleLogin.fulfilled, handleAsyncAction)
      .addCase(creatorGoogleLogin.rejected, handleAsyncAction)

      .addCase(creatorRegister.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorRegister.fulfilled, handleAsyncAction)
      .addCase(creatorRegister.rejected, handleAsyncAction)

      .addCase(updateCreator.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCreator.fulfilled, handleAsyncAction)
      .addCase(updateCreator.rejected, handleAsyncAction)

      .addCase(creatorLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorLogout.fulfilled, handleAsyncAction)
      .addCase(creatorLogout.rejected, handleAsyncAction)

      .addCase(getCreator.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCreator.fulfilled, handleAsyncAction)
      .addCase(getCreator.rejected, handleAsyncAction)
  },
});

export const { creatorClearError } = creatorSlice.actions;
export default creatorSlice.reducer;