import React from "react";
import { Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from "./../../images/Logo.svg";
import SmallLogo from "./../../images/LogoImg.svg";
import LoginMenu from "./LoginMenu";
import UserMenu from "./UserMenu";
import CreatorMenu from "./CreatorMenu";
import AdminMenu from "./AdminMenu";
import SearchBar from "../searchBar/SearchBar";
import Categories from "./Categories";
import { useSelector } from "react-redux";
import NavbarDrawer from "./NavbarDrawer";

const Navbar = () => {
  const { user } = useSelector((state) => state.user);
  const { creator } = useSelector((state) => state.creator);
  const { admin } = useSelector((state) => state.admin);
  const [isSmallerThan1080] = useMediaQuery('(max-width: 1080px)');
  const [isSmallerThan700] = useMediaQuery('(max-width: 700px)');


  const navBtnStyle = {
    fontFamily: '"Outfit", sans-serif',
    fontWeight: 400,
    fontSize: "17px",
    padding: "5px 25px",
    borderRadius: "20px",
    color: "#FFFFFF",
    backgroundColor: "#662D91",
  };

  const modalOverlayStyle = { background: "rgba(42,39,39,0.35)", backdropFilter: "blur(3px)" };
  const modalContentStyle = { position: "absolute", top: "5px", right: "18px", width: "250px", background: "#662D91", color: "white" };

  return (
    <Flex bgColor={"#fff"} align={"center"} p={"8px 10px"} w={"95%"} gap={6} m={"auto"} justify={"space-between"}>
      {isSmallerThan1080 && <NavbarDrawer/>}
      <Link to={"/"}>
        <Image src={isSmallerThan700?SmallLogo:Logo} alt="WEZLAA" h={"32px"} />
      </Link>
      {!isSmallerThan1080 &&
        <Flex align={"center"} gap={2}>
          <Categories />
        </Flex>
      }
      <Flex flex={1} align={"center"} justify={"flex-end"} gap={"24px"}>
        <SearchBar />
        {!isSmallerThan1080 && <><Link to={"/"}><Text className="navText">Home</Text></Link>
        <Link to={"/about"}><Text className="navText">About Us</Text></Link></>}
        {!user && !creator && !admin && <LoginMenu btnStyle={navBtnStyle} overlayStyle={modalOverlayStyle} contentStyle={modalContentStyle} />}
        {user && <UserMenu btnStyle={navBtnStyle} overlayStyle={modalOverlayStyle} contentStyle={modalContentStyle} />}
        {creator && <CreatorMenu btnStyle={navBtnStyle} overlayStyle={modalOverlayStyle} contentStyle={modalContentStyle} />}
        {admin && <AdminMenu btnStyle={navBtnStyle} overlayStyle={modalOverlayStyle} contentStyle={modalContentStyle} />}
      </Flex>
    </Flex>
  );
};

export default Navbar;