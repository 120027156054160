import React from 'react';
import 'react-quill/dist/quill.snow.css';
import {Heading} from '@chakra-ui/react'

const dataCustomize = (htmlContent) => {
  
  // let adjustedContent = String(htmlContent);
  let adjustedContent = htmlContent;
  adjustedContent = adjustedContent.replace(/<ol/g, '<ol style="margin-left: 30px;" ');
  adjustedContent = adjustedContent.replace(/<ul/g, '<ul style="margin-left: 30px;" ');
  adjustedContent = adjustedContent.replace(/class="ql-align-right"/g, 'style="text-align:end;"');
  adjustedContent = adjustedContent.replace(/class="ql-align-center"/g, 'style="text-align:center;"');
  adjustedContent = adjustedContent.replace(/class="ql-align-justify"/g, 'style="text-align:justify;"');
  return adjustedContent;
};

const HtmlDataParse = ({ htmlContent }) => {
  const adjustedHTMLContent = dataCustomize(htmlContent);

  return (
    <div className="courseDescText" dangerouslySetInnerHTML={{ __html: adjustedHTMLContent }}/>
  );
};

export default HtmlDataParse;
