import React from 'react'
import {Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalOverlay, Text, useDisclosure, useToast} from '@chakra-ui/react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addCategory } from '../../store/admin/adminAction';

const AddCategory = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        handleSubmit,
        register,
        formState: { errors },
      } = useForm();    
    const dispatch = useDispatch();
    const toast = useToast();
    const submitHandler = async(data) => {
        const result = await dispatch(addCategory(data));
        if(result.payload.success){toast({
            description: result.payload.message, // Adjust based on your user data structure
            status: "success",
            duration: 3000,
            isClosable: true,
          });}
        if(result.payload.errorMessage){toast({
            description: result.payload.errorMessage, // Adjust based on your user data structure
            status: "error",
            duration: 3000,
            isClosable: true,
          });}
        onClose();
    };
  return (
    <>
      <Text px={2} py={1} borderRadius={"4px"} bg={"gray.400"} cursor={"pointer"} onClick={onOpen}>
        Add Category
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay
          style={{
            background: "rgba(42,39,39,0.35)",
            backdropFilter: "blur(3px)",
          }}
        />
        <ModalContent backgroundColor={"transparent"} boxShadow={"none"}>
          <ModalBody>
            <Flex
              backgroundColor={"white"}
              boxShadow={"none"}
              padding={"25px 15px"}
              borderRadius={"15px"}
              flexDir={"column"}
              gap={5}
            >
              <Text as={"b"} fontSize={"lg"} fontFamily={"Outfit,sans-serif"}>
                Add Category
              </Text>
              <form
                onSubmit={handleSubmit(submitHandler)}
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <FormControl>
                  <FormLabel>Name:</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    autoComplete="off"
                    placeholder="Enter category name"
                    {...register("name", { required: "Name is required" })}
                  />
                  {errors.name && (
                    <p className="formError">{errors.name.message}</p>
                  )}
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  borderRadius={"8px"}
                  bg={"#662D91"}
                  fontFamily={"Outfit"}
                  fontWeight={400}
                  alignSelf={"flex-end"}
                >
                  Submit
                </Button>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCategory