import React, { useEffect } from "react";
import { fetchCategories } from "../../store/admin/adminAction";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Heading, Text } from "@chakra-ui/react";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";

const AdminCategories = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state)=>state.categories);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);
  return (
    <Flex flexDir={"column"} align={"center"} width={"90%"} fontFamily={"Outfit, sans-serif"} m={"auto"} minH={"90vh"}>
      <Flex justify={"space-between"} align={"center"} w={"100%"} my={4}>
        <Heading as={"h4"} fontSize={"2xl"}>Categories</Heading>
        <AddCategory/>
      </Flex>
      <Flex w={"100%"} flexDir={"column"} gap={4}>
      {categories?.map((category) => {
        return (
          <Flex flexDir={"column"} gap={3}>
          <Text as={'b'} fontSize={"lg"}>{category.name}</Text>
          <Flex wrap={"wrap"} align={"center"} gap={2}>
            {category.subCategories?.map((category)=>{return(<Text px={2} py={1} borderRadius={"4px"} bg={"gray.200"}>{category.name}</Text>)})}
            <AddSubCategory categoryId={category._id}/>
          </Flex>
          </Flex>
        );
      })}
      </Flex>
    </Flex>
  );
};

export default AdminCategories;
