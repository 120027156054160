import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import UserProfile from "./pages/user/UserProfile";
import { useEffect } from "react";
import { checkAuth } from "./store/configActions";
import { useDispatch } from "react-redux";
import SecureRoute from "./routes/secureRoute";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Wishlist from "./pages/user/Wishlist";
import './App.css'
import CourseDescription from "./pages/course/courseDescription";
import ErrorPage from "./pages/ErrorPage";
import CreatorProfile from "./pages/creator/Profile";
import CreatorReports from "./pages/creator/CreatorReports";
import AdminProfile from "./pages/admin/Profile";
import CreatorCourses from "./pages/creator/Courses";
import AdminReports from "./pages/admin/Reports";
import AdminCourses from "./pages/admin/Courses";
import AboutUs from "./pages/AboutUs";
import AdminCategories from "./pages/admin/Categories";
import ResetPassword from "./components/auth/ResetPassword";
import Explore from "./pages/Explore/Explore";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/course/:courseId" element={<CourseDescription/>} />
        <Route exact path="/learn/:categoryUrl" element={<Explore/>} />
        <Route exact path="/learn/:categoryUrl/:subCategoryUrl" element={<Explore/>} />
        <Route exact path="/about" element={<AboutUs/>} />
        <Route exact path="/:profile/password/reset/:token" element={<ResetPassword/>} />
        <Route path="/user" element={<SecureRoute profile="user" />}>
          <Route index element={<UserProfile />} />
          <Route path="wishlist" element={<Wishlist />} />
        </Route>
        <Route path="/creator" element={<SecureRoute profile="creator" />}>
          <Route exact index element={<CreatorProfile/>} />
          <Route exact path="reports" element={<CreatorReports />} />
          <Route exact path="courses" element={<CreatorCourses />} />
        </Route>
        <Route path="/admin" element={<SecureRoute profile="admin" />}>
          <Route index element={<AdminProfile/>} />
          <Route exact path="courses" element={<AdminCourses />} />
          <Route exact path="reports" element={<AdminReports />} />
          <Route exact path="categories" element={<AdminCategories />} />
        </Route>
        <Route path='*' exact={true} element={<ErrorPage/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
