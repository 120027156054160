import { Button, Flex, Input, Text, FormLabel, FormControl, Heading, Avatar, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getAdmin, updateAdmin } from '../../store/admin/adminAction'
import LoadingPage from "../LoadingPage";
import { adminClearError } from "../../store/admin/admin";

const AdminProfile = () => {
  const { loading, admin } = useSelector((state) => state.admin)
  const dispatch = useDispatch();
  const toast = useToast();
  const profileStyle = {
    fontFamily: '"Outfit",sans-serif',
  }
  const [updatedAdmin, setUpdatedAdmin] = useState({ ...admin });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAdmin((prevAdmin) => ({ ...prevAdmin, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUpdatedAdmin((prevAdmin) => ({ ...prevAdmin, ["profileImage"]: reader.result }));
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const result = await dispatch(updateAdmin(updatedAdmin));
    if (result.payload.admin) {
      toast({
        description: `Updated Successfully`, // Adjust based on your user data structure
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (result.payload.errorMessage) {
      toast({
        description: result.payload.errorMessage, // Adjust based on your user data structure
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(adminClearError());
    }
  };

  const formLabelStyle = {
    fontWeight: 600,
    margin: "0px"
  }
  useEffect(() => {
    dispatch(getAdmin());
  }, [])
  useEffect(() => {
    setUpdatedAdmin({ ...admin });
  }, [admin])
  return (
    loading ? <LoadingPage /> : admin && (
      <>
        <Flex align={"center"} p={"30px 5vw"} wrap={"wrap"} gap={5} bg={"linear-gradient(90deg, #FAFCFF 0%, rgba(102, 45, 145, 0.64) 62.29%, #662D91 100%)"} w={"100%"}>
          <Avatar src={admin.image?.url} boxSize={"150px"} />
          <Flex flexDir={"column"} color={"#662D91"}>
            <Heading as={"h2"} size={"lg"}>{admin.firstName} {admin.lastName}</Heading>
            <Text size={"lg"}>{admin.email}</Text>
          </Flex>
        </Flex>
        <Flex w={"100%"} flexDir={"column"} align={"center"} p={"30px 5vw"} gap={5}>
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              width:"60%",
              minWidth:"350px"
            }}
          >
            <FormControl style={profileStyle}>
              <FormLabel style={formLabelStyle}>Email</FormLabel>
              <Input
                disabled={true}
                type="email"
                value={updatedAdmin.email}
              />
            </FormControl>
            <FormControl style={profileStyle} isRequired>
              <FormLabel style={formLabelStyle}>First Name</FormLabel>
              <Input
                type="text"
                name="firstName"
                value={updatedAdmin.firstName}
                onChange={handleChange}
                placeholder="Enter your First Name"
              />
            </FormControl>
            <FormControl style={profileStyle} isRequired>
              <FormLabel style={formLabelStyle}>Last Name</FormLabel>
              <Input
                type="text"
                name="lastName"
                value={updatedAdmin.lastName}
                onChange={handleChange}
                placeholder="Enter your Last Name"
              />
            </FormControl>
            <FormControl style={profileStyle}>
              <FormLabel style={formLabelStyle}>Profile Image</FormLabel>
              <input type="file" accept="image/*" onChange={handleImageUpload} />
            </FormControl>
            <FormControl style={profileStyle}>
              <FormLabel style={formLabelStyle} isRequired>Contact Number</FormLabel>
              <Input
                type="number"
                name="contactNumber"
                value={updatedAdmin.contactNumber}
                onChange={handleChange}
                placeholder="Enter your contact Number"
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              mt={3}
              borderRadius={"8px"}
              bg={"#662D91"}
              fontFamily={"Outfit"}
              fontWeight={400}
              w={"100px"}
              disabled={loading}
            >
              {!loading ? "Update" : "Updating"}
            </Button>
          </form>
        </Flex>
      </>
    )
  )
}

export default AdminProfile